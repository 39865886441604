<template>
    <div class="popover-window header-popover header-popover-account" v-if="showAccount" @click.stop>
        <div class="popover-window__wrapper shadow px-4">
            <h3 class="mb-4">Аккаунт</h3>

            <template v-if="!isAuth">
                <a href="/login/" class="button button_color_green button_block">
                    <span class="text text_transform_upper">Войти</span>
                </a>
            </template>

            <template v-if="isAuth">
                <div class="my-3 text text_weight_bold">
                    Привет, {{ userName }}!
                </div>

                <div class="my-3">
                    <a href="/my/" class="button button_color_green button_outline button_block">
                        <span class="text text_transform_upper">Личный кабинет</span>
                    </a>
                </div>

                <div class="my-3">
                    <a href="?logout" class="button button_color_green button_block">
                        <span class="text text_transform_upper">Выход</span>
                    </a>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            bus: () => window.bus,
            isAuth: () => window.wa_is_auth,
            userName: () => window.wa_user_name,
            showAccount(){
                return this.bus.showAccount.value
            },
        }
    }
</script>