const objectToArray = (object) => {
    return Object.keys(object).map(k => object[k])
}

const execOnClick = (event, closure) => {
    event.stopPropagation()

    event.preventDefault()

    closure()

    return false
}

export {
    objectToArray,
    execOnClick
}