<template>
    <div class="modal__wrapper w-100">
        <div class="modal__header p-2">
            <a href="javascript:void(0)" class="modal__close">
                <button class="modal__close_button" @click="closeModal">Назад</button>
            </a>
        </div>
        <div class="modal__content">
            <div id="forpvz" style="height:100%"></div>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'city',
            'apiKey'
        ],

        mounted() {
             var self = this;

            let defaultLocation = 'Владивосток';
            if (self.city && self.city !== '')
                defaultLocation = self.city;

            let path = window.location.origin + '/service.php';

            new window.CDEKWidget({
                from: 'Владивосток',
                root: 'forpvz',
                apiKey: self.apiKey,
                servicePath: path,
                defaultLocation: defaultLocation,
                canChoose: true,

                onChoose(delivery_mode ,tarif, address) {
                    let delivery_address = '';
                    if (delivery_mode === 'office'){
                        delivery_address = 'Доставка в ' + address.city + ' до ПВЗ на ' + address.address;
                    } else if(delivery_mode === 'door'){
                        delivery_address = 'Доставка до двери ' + address.formatted;
                    }
                    self.$emit('change_SDEK_PVZ', delivery_address);
                    self.closeModal();
                }
            });

        },

        methods: {
            closeModal() {
                this.$emit('closeModal');
            }
        },
    }
</script>
