<template>
    <div>
        <template v-if="!notEmptyCarts.length">

            <div class="block-left-right my-sm-4">
                <div class="my-3 my-sm-0">
                    <span class="text text_size_lg">
                        Ваша корзина пуста
                    </span>
                </div>
                <div class="my-3 my-sm-0">
                    <a href="/" class="text text_size_lg text_color_gray text_weight_bold">
                        На главную
                    </a>
                </div>
            </div>

        </template>
        <template v-else>
            <div class="block-left-right my-sm-4 ml-3">
                <div class="my-3 my-sm-0">
                    <div class="text text_size_lg row">
                        <div class="sm-6">Количество товаров в корзине: <span class="text text_color_green text_weight_bold">{{ totalItemsCount }}</span>&nbsp;</div>
                        <div class="sm-6 ml-sm-2">На сумму:
                            <span title="Обновить стоимость" class="text text_color_green text_weight_bold cursor_pointer" @click="reload">{{ totalPrice }} руб.</span>
                        </div>
                    </div>
                    <div class="text text_size_lg row mt-3" v-if="isAuth">
                        <div class="sm-6">
                            <i style="color: lightgray;" class="fa fa-exclamation-circle" title="Бонусы можно списать при оформлении заказа"></i>
                            Бонусы: <span class="text text_color_green text_weight_bold">{{ bonuses }}</span>
                            &nbsp;
                        </div>
                    </div>
                </div>
                <div class="my-3 my-sm-0">
                    <a href="/" class="text text_size_lg text_color_gray text_weight_bold">
                        Продолжить покупки
                    </a>
                </div>
            </div>

            <!-- Корзина (сгруппированная) -->
            <CartPageGroup v-for="cart in notEmptyCarts" class="mt-2 mb-4" :cart="cart" :key="cart.id"/>
        </template>
    </div>
</template>

<script>

    import CartPageGroup from './CartPageGroup'

    export default {

        components: {
            CartPageGroup
        },

        created() {
            this.carts = JSON.parse(document.getElementById('ajs_carts').value);

            if (window.location.href.match('position-of-the-set=true') !== null) {
                this.bus.message('В корзине присутствует товар, который также входит в комплект. Для оформления заказа удалите данный товар из корзины либо удалите комплект, содержащий данный товар.');
                window.history.replaceState({}, 'AJS', '/cart/')
            }

            if (window.location.href.match('stock-changed=true') !== null) {
                this.bus.message('На складе недостаточно товара для оформления заказа. Количество в корзине было изменено на маскимально возможное для товаров у которых превышен остаток.');
                window.history.replaceState({}, 'AJS', '/cart/')
            }
        },

        computed: {
            bus: () => window.bus,

            isAuth() {
                return window.wa_is_auth
            },

            totalPrice() {
                return this.notEmptyCarts.map(c => c.total).reduce((a, c) => a + c).priceFormat(2)
            },

            totalItemsCount() {
                return this.notEmptyCarts.map(c => c.items.length).reduce((a, c) => a + c)
            },

            notEmptyCarts() {
                return this.carts.filter(c => c.items.length)
            },

            bonuses(){
                return this.notEmptyCarts[0].balanceOfBonuses
            }
        },

        methods: {
            reload() {
                window.location.reload()
            }
        },

        data: () => ({
            carts: null
        })
    }
</script>