<template>
    <div class="mb-3">
        <template v-if="!order">

            <CheckoutFormStep1 @onCheckout="onCheckout" :class="{'d-none': params}"/>

            <CheckoutFormStep2 v-if="params" :params="params" @onOrderCreated="onOrderCreated" @back="params = null"/>

        </template>

        <template v-else>
            <p>Заказ успешно сформирован!</p>
            <p>ID {{ order.id }}</p>
            <div ref="paymentHtmLContainer"></div>
        </template>
    </div>
</template>

<script>
    import CheckoutFormStep1 from './CheckoutFormStep1'
    import CheckoutFormStep2 from './CheckoutFormStep2'

    export default {
        components: {
            CheckoutFormStep1,
            CheckoutFormStep2
        },

        created() {
            if (window.location.pathname === '/checkout/success/') {
                try {
                    const order = JSON.parse(window.localStorage.getItem('last_order'))
                    const payment = window.localStorage.getItem('last_order_payment')

                    this.order = order
                    this.showPaymentHtml(payment)
                } catch (e) {
                    console.error(e)
                }
            }
        },

        data: () => ({
            params: null,
            order: null
        }),

        methods: {

            onCheckout(params) {
                this.params = params
            },

            onOrderCreated({order, payment}) {

                window.localStorage.setItem('last_order', JSON.stringify(order))
                window.localStorage.setItem('last_order_payment', payment)

                this.order = order

                history.pushState({}, "Заказ создан", "/checkout/success/");

                window.bus.refreshCartInfo()

                if (payment) {
                    this.showPaymentHtml(payment)
                }
            },

            showPaymentHtml(payment) {
                this.$nextTick(() => {
                    window.jQuery(this.$refs.paymentHtmLContainer).html(payment)
                })
            }
        }
    }
</script>