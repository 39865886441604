<template>
    <div class="row mb-3">
        <div class="col-md-9">

            <div>
                <input id="agree" type="checkbox" class="mr-2" v-model="termsOk">

                <label for="agree" class="d-inline text text_color_gray text_size_xl">
                    С <a href="/warranty" target="_blank" class="text_color_green">условиями гарантии и возврата</a> ознакомлен
                </label>
            </div>

            <div>
                <p>Сумма заказа: {{ Number(params.subtotal).priceFormat(2) }} {{params.sign}}</p>
                <p>Скидка <span v-if="params.bonuses">(с учетом бонусов)</span>: {{ Number(params.discount).priceFormat(2) }} {{params.sign}}</p>
                <p>Доставка: {{ Number(params.shipping).priceFormat(2) }} {{params.sign}}</p>
                <p>Упаковка: {{ Number(params.packaging).priceFormat(2) }} {{params.sign}}</p>
                <p><b>К оплате: {{ Number(params.total).priceFormat(2) }} {{params.sign}}</b></p>
            </div>

            <div class="row">
                <div class="col-md-6 text-left">
                    <button @click="back" class="button button_color_green button_outline">&lt;&lt; Назад</button>
                </div>

                <div class="col-md-6 text-right">
                    <button class="button button_color_green" @click="makeOrder" :disabled="wait">
                        <span class="px-3">Создать заказ</span>
                    </button>
                </div>
            </div>

        </div>
    </div>
</template> 

<script>
    export default {

        props: [
            'params'
        ],

        data: () => ({
            nativeCartCode: null,
            termsOk: false,
            wait: false
        }),

        created() {
            this.nativeCartCode = document.getElementById('checkout-native-cart-code').value
        },

        computed: {
            bus: () => window.bus
        },

        methods: {

            back() {
                this.$emit('back')
            },

            async makeOrder() {

                if (!this.termsOk) {
                    return alert('Чтобы оформить заказ нужно согласиться с условиями гарантии и возврата.')
                }


                this.wait = true

                try {
                    const response = await window.api.makeOrder({}, this.nativeCartCode);

                    this.$emit('onOrderCreated', response);

                } catch (e) {

                    if (typeof e === 'string') {
                        this.bus.message(e)
                    } else {
                        // Ошибка валидации формы.
                        Object.keys(e).map(k => {
                            this.bus.message(e[k])
                        })
                    }
                } finally {
                    this.wait = false
                }
            }

        }
    }
</script>