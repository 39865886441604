import { createApp } from 'vue'
import 'bootstrap';

//подключенные компоненты на всём сайте
import HeaderCatalog from './components/HeaderCatalog.vue';
import SearchPopover from './components/SearchPopover.vue';
import AccountPopover from './components/AccountPopover.vue';
import FavoritesPopover from './components/FavoritesPopover.vue';
import CartPopover from './components/CartPopover.vue';
import HeaderMobileMenu from './components/HeaderMobileMenu.vue';
import ModalWindow from "./components/ModalWindow.vue";

//подключенные компоненты опционально
import CartPage from './components/cart-page/CartPage.vue';
import ContactsModalMap from "./components/ContactsModalMap.vue";
import ProductPageInfo from './components/product-page/ProductPageInfo.vue';
import CheckoutPage from './components/checkout-page/CheckoutPage.vue';
import ProfilePage from './components/my/profile/ProfilePage.vue';
import ProductPageImages from './components/product-page/ProductPageImages.vue';

/*не используются*/
//import CatalogPageFilter from './components/catalog-page/CatalogPageFilter.vue';
//import SignUp from './components/my/signup/SignUp.vue';

import {execOnClick} from './common.js';
import bus from './bus.js';
import api from './api.js';
import './scss/main.scss';

import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';

Number.prototype.format = function(n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

Number.prototype.priceFormat = function(n) {
    return this.format(n, 3, ' ', '.');
};

window.Vue = createApp;
window.bus = bus;
window.api = api;
window.execOnClick = execOnClick;


const createMyApp = options => {
    const app = createApp(options);
    return app;
};

//подключенные компоненты на всём сайте
createMyApp(HeaderCatalog).mount('#header-catalog');
createMyApp(SearchPopover).mount('#search-popover');
createMyApp(AccountPopover).mount('#account-popover');
createMyApp(FavoritesPopover).mount('#favorites-popover');
createMyApp(CartPopover).mount('#cart-popover');
createMyApp(HeaderMobileMenu).mount('#header-mobile-menu');
createMyApp(ModalWindow).mount('#modal-window');

//подключенные компоненты опционально
if (document.getElementById('cart-page')) {
    createMyApp(CartPage).mount('#cart-page');
}

if (document.getElementById('contacts-modal-map-vue')) {
    createMyApp(ContactsModalMap).mount('#contacts-modal-map-vue');
}

if (document.getElementById('product-page-info')) {
    createMyApp(ProductPageInfo).mount('#product-page-info');
}

if (document.getElementById('checkout-page')) {
    createMyApp(CheckoutPage).mount('#checkout-page');
}

if (document.getElementById('profile-vue-mountpoint')) {
    createMyApp(ProfilePage).mount('#profile-vue-mountpoint');
}

if (document.getElementById('product-page-images')) {
    createMyApp(ProductPageImages).mount('#product-page-images');
}

window.onclick = () => {
    bus.outsideClick()
};

bus.refreshCartInfo();
bus.refreshFavorites();