<template>
    <div class="popover-window header-popover header-popover-favorites" v-if="showFavorites" @click.stop>
        <div class="popover-window__wrapper shadow">
            <h3 class="mb-3">Мое избранное</h3>

            <div class="container">
                <div class="row">
                    <div class="col-6 mb-2" v-for="p in favorites" :key="p.id">
                        <div class="product-card product-card_border p-2">
                            <div class="product-card__image-wrapper">
                                <a :href="p.frontend_url">
                                    <img class="product-card__image" :src="p.image_url">
                                </a>
                            </div>

                            <div class="product-card__info-wrapper">
                                <div class="product-card__prices block-left-right">
                                    <div class="text text_color_green">{{p.price}} <span class="ruble">Р</span>.</div>
                                </div>

                                <p class="product-card__description">
                                    {{ p.name }}
                                </p>


                                <div class="product-card__statistic block-left-right">
                                    <div class="text text_color_gray product-card__orders-count">
                                        {{ p.total_sales }} заказов
                                    </div>

                                    <stars :rate="p.rating"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            bus: () => window.bus,
            showFavorites(){
                return this.bus.showFavorites.value
            },
            favorites(){
                return this.bus.favorites.value
            },
        }
    }
</script>