<template>
    <div>

        <div class="row mt-4">
            <div class="col-sm-5">
                <div class="block-left-right mb-2">
                    <h4>Дата регистрации</h4>
                    <span class="text text_color_gray">{{ form.create_datetime_formatted }}</span>
                </div>
                <div class="hr"></div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-sm-5">

                <!-- Данные организации -->
                <!-- Личные данные -->
                <div>

                        <h4 class="mt-2 mb-2">Личные данные</h4>
                        <div class="hr my-2"></div>
                        <!--<span class="text text_color_gray">-->
                        <!--Здесь вы можете редактировать личные данные-->
                        <!--</span>-->

                        <div style="display:none">{{form.birthday}}</div>

                        <div class="row my-2">
                            <label class="col-12">
                                Фамилия <span class="text text_color_red">*</span>
                            </label>
                            <div class="col-12">
                                <input type="text" class="input" v-model="form.lastname" readonly @click="showLastname = ! showLastname">
                            </div>
                        </div>
                        <div class="row my-2" v-show="showLastname">
                            <div class="col-12">
                                <div style="border: 1px solid #ced4da; padding: 8px;">
                                    <span v-html="showSupportBlock('фамилии')"></span>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <label class="col-12">
                                Имя <span class="text text_color_red">*</span>
                            </label>
                            <div class="col-12">
                                <input type="text" class="input" v-model="form.firstname" readonly @click="showName = ! showName">
                            </div>
                        </div>
                        <div class="row my-2" v-show="showName">
                            <div class="col-12">
                                <div style="border: 1px solid #ced4da; padding: 8px;">
                                    <span v-html="showSupportBlock('имени')"></span>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <label class="col-12">
                                Отчество <span class="text text_color_red">*</span>
                            </label>
                            <div class="col-12">
                                <input type="text" class="input" v-model="form.middlename" :readonly="serverMiddlename !== ''" @click="showMiddlename = !showMiddlename">
                            </div>
                        </div>
                        <div class="row my-2" v-show="showMiddlename && serverMiddlename !== ''">
                            <div class="col-12">
                                <div style="border: 1px solid #ced4da; padding: 8px;">
                                    <span v-html="showSupportBlock('отчества')"></span>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">

                                <label class="col-3" style="padding-right: 0">
                                    Дата рождения<span class="text text_color_red">*</span>
                                </label>

                                <div class="col-2" style="padding-right: 5px; padding-left:0">
                                    <select class="input" v-model="form.birth_day">
                                        <template v-for="d in days" :key="d">
                                            <option :value="d">{{ d }}</option>
                                        </template>
                                    </select>
                                </div>

                            <div class="col-3" style="padding-right: 5px; padding-left:0">
                                <select class="input" v-model="form.birth_month">
                                    <template v-for="m in months" :key="m.key">
                                        <option :value="m.key">{{ m.data }}</option>
                                    </template>
                                </select>
                            </div>

                            <div class="col-3" style="padding-right: 0; padding-left:0">
                                <select class="input" v-model="form.birth_year">
                                    <template v-for="y in years" :key="y">
                                        <option :value="y">{{ y }}</option>
                                    </template>
                                </select>
                            </div>
                        </div>
                        <div class="row my-2" v-show="serverBirthDay !== '' && serverBirthDay">
                            <div class="col-12">
                                <div style="border: 1px solid #ced4da; padding: 8px;">
                                    <span v-html="showSupportBlock('даты рождения')"></span>
                                </div>
                            </div>
                        </div>

                        <div class="row my-2">
                            <label class="col-12">
                                Паспорт (серия и номер)
                            </label>
                            <div class="col-12">
                                <input type="text" class="input" v-model="form['passport']">
                            </div>
                        </div>


                    <div class="row my-2">
                        <label class="col-12">
                            Email <span class="text text_color_red">*</span>
                        </label>
                        <div class="col-12">
                            <input type="text" class="input" v-model="form.email">
                        </div>

<!--
                        <div class="col-12">
                            <span class="text text_color_gray" v-if="form.email_confirmed">
                                При смене e-mail потребуется подтверждение
                            </span>
                            <span class="text text_color_gray" v-else>
                                <template v-if="confirmSended">
                                    Ссылка отправлена на почту
                                </template>
                                <template v-else>
                                    Требуется подтверждение.
                                    <a href="javascript:void(0)" @click="sendConfirm">Подтвердить.</a>
                                </template>
                            </span>
                        </div>
-->



                    </div>

                    <div class="row my-2">
                        <label class="col-12">
                            Страна<span class="text text_color_red">*</span>
                        </label>
                        <div class="col-12">
                            <select class="input" v-model="form.addressShipping.country" @change="setMaskPhone()">
                                <template v-for="c in countries" :key="c.code">
                                    <option :value="c.code">{{ c.name }}</option>
                                </template>
                            </select>
                        </div>
                    </div>

                    <div class="row my-2">
                        <label class="col-12">
                            Телефон
                        </label>
                        <div class="col-12">
                            <input type="tel" class="input" v-model="form.phone" ref="phone" :readonly="serverPhone !== ''" @click="showTel = !showTel">
                        </div>
                    </div>
                    <div class="row my-2" v-show="showTel && serverPhone !== ''">
                        <div class="col-12">
                            <div style="border: 1px solid #ced4da; padding: 8px;">
                                <span v-html="showSupportBlock('телефона')"></span>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Адрес доставки -->
                <div>
                    <h4 class="mt-5 mb-2">Адрес доставки</h4>
                    <div class="hr my-2"></div>


                    <template v-if="form.addressShipping.country=='rus'">
                        <div class="row my-2">
                            <label class="col-12">
                                Регион<span class="text text_color_red">*</span>
                            </label>
                            <div class="col-12">
                                <select class="input" v-model="form.addressShipping.region">
                                    <option v-for="r in regions" :value="r.code" :key="r.code">{{ r.name }}</option>
                                </select>
                            </div>
                        </div>
                    </template>


                    <div class="row my-2">
                        <label class="col-12">
                            Почтовый индекс
                        </label>
                        <div class="col-12">
                            <input type="text" class="input" v-model="form.addressShipping.zip">
                        </div>
                    </div>


                    <div class="row my-2">
                        <label class="col-12">
                            Населенный пункт (Город, Поселок, Село)
                        </label>
                        <div class="col-12">
                            <input type="text" class="input" v-model="form.addressShipping.city">
                        </div>
                    </div>

                    <div class="row my-2">
                        <label class="col-12">
                            Улица, Дом, Квартира
                        </label>
                        <div class="col-12">
                            <input type="text" class="input" v-model="form.addressShipping.street">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-5">

                <div class="mt-2">
                    <h4>
                        <label class="text text_size_xl">
                            <input type="checkbox" v-model="form.is_company_selected">
                            Указать данные организации
                        </label>
                    </h4>
                    <div class="hr"  style="margin-top: -5px"></div>
                </div>

                <template v-if="form.is_company_selected">
                    <div  style="margin-top: -2px">
                        <div class="row my-2">
                            <label class="col-12">
                                Название организации (ООО, ИП, т и.д.)<span class="text text_color_red">*</span>
                            </label>
                            <div class="col-12">
                                <input type="text" class="input" v-model="form.company">
                            </div>
                        </div>

                        <div class="row my-2">
                            <label class="col-12">
                                Грузополучатель
                            </label>
                            <div class="col-12">
                                <input type="text" class="input" v-model="form.gruzopoluchatel">
                            </div>
                        </div>
                    </div>


                    <div class="row my-2">
                        <label class="col-12">
                            ИНН<span class="text text_color_red">*</span>
                        </label>
                        <div class="col-12">
                            <input type="text" class="input" required v-model="form.inn">
                        </div>
                    </div>

                    <div class="row my-2">
                        <label class="col-12">
                            Расчетный счет <span class="text text_color_red">*</span>
                        </label>
                        <div class="col-12">
                            <input type="text" class="input" v-model="form.rs">
                        </div>
                    </div>

                    <div class="row my-2">
                        <label class="col-12">
                            Корреспондентский счет <span class="text text_color_red">*</span>
                        </label>
                        <div class="col-12">
                            <input type="text" class="input" v-model="form.korschet">
                        </div>
                    </div>

                    <div class="row my-2">
                        <label class="col-12">
                            КПП
                        </label>
                        <div class="col-12">
                            <input type="text" class="input" v-model="form.kpp">
                        </div>
                    </div>

                    <div class="row my-2">
                        <label class="col-12">
                            Наименование банка<span class="text text_color_red">*</span>
                        </label>
                        <div class="col-12">
                            <input type="text" class="input" v-model="form.bank">
                        </div>
                    </div>

                    <div class="row my-2">
                        <label class="col-12">
                            БИК<span class="text text_color_red">*</span>
                        </label>
                        <div class="col-12">
                            <input type="text" class="input" v-model="form.bik">
                        </div>
                    </div>
                </template>

                <!-- Смена пароля -->
                <div class="mt-2">
                    <h4 class="mt-5 mb-2">Смена пароля</h4>
                    <div class="hr my-1"></div>
                    <span class="text text_color_gray">
                        Вы можете изменить свой пароль для входа на сайт
                    </span>

                    <div class="row my-2">
                        <label class="col-12">
                            Новый пароль
                        </label>
                        <div class="col-12">
                            <input type="text" class="input" v-model="form.password">
                        </div>
                    </div>

                    <div class="row my-2">
                        <label class="col-12">
                            Подтвердите пароль
                        </label>
                        <div class="col-12">
                            <input type="text" class="input" v-model="form.password_confirm">
                        </div>
                    </div>
                </div>


                <!-- Соц сети -->
                <!--<div>-->
                <!--<h4 class="mt-5 mb-2">Вход через соц. сети</h4>-->
                <!--<div class="hr my-2"></div>-->
                <!--<span class="text text_color_gray">-->
                <!--Нажмите, чтобы привязать вашу учетную запись для входа через соцсети-->
                <!--</span>-->
                <!--</div>-->

                <!-- Дополнительно -->
                <!--<div class="mb-4">-->
                <!--<h4 class="mt-5 mb-2">Дополнительно</h4>-->
                <!--<div class="hr my-2"></div>-->

                <!--<a href="#" class="text text_color_green">-->
                <!--Отозвать согласие на <br>обработку персональных данных-->
                <!--</a>-->
                <!--</div>-->
            </div>
        </div>

        <div class="my-3">
            <button type="button" class="button button_color_green mr-2" @click="save" :disabled="wait || !changed">
                <span v-if="wait">Сохранение...</span>
                <span v-else>Сохранить</span>
            </button>
        </div>
    </div>
</template>

<script>
   import Inputmask from "inputmask"

    export default {

        watch: {
            'form': {
                handler: function () {
                    this.changed = true;
                },
                deep: true
            }
        },

        data: () => ({
            changed: false,
            wait: false,
            cartItemsCount: 0,
            favoritesItemsCount: 0,
            form: null,
            confirmSended: false,
            years: [],
            days: [],
            tel_without_code :null,
            months: {
                1:{
                    'key':'1',
                    'data':'Января'
                    },
                2:{
                    'key':'2',
                    'data':'Февраля'
                },
                3:{
                    'key':'3',
                    'data':'Марта'
                },
                4:{
                    'key':'4',
                    'data':'Апреля'
                },
                5:{
                    'key':'5',
                    'data':'Мая'
                },
                6:{
                    'key':'6',
                    'data':'Июня'
                },
                7:{
                    'key':'7',
                    'data':'Июля'
                },
                8:{
                    'key':'8',
                    'data':'Августа'
                },
                9:{
                    'key':'9',
                    'data':'Сентября'
                },
                10:{
                    'key':'10',
                    'data':'Октября'
                },
                11:{
                    'key':'11',
                    'data':'Ноября'
                },
                12:{
                    'key':'12',
                    'data':'Декабря'
                },
            },
            showLastname: false,
            showName: false,
            showMiddlename: false,
            showTel: false,
            showBirthDay: false,

            serverPhone: null, //телефон, который получаем с сервера
            serverMiddlename: null, //отчество с сервера

            serverBirthDay: null, //день рождения с сервера,
            serverBirthMonth: null, //день рождения с сервера
            serverBirthYear: null //день рождения с сервера
        }),

        created() {
            let date = new Date();
            let current_year = date.getFullYear();

            for (let i = 1903; i <=current_year; i++){
                this.years.push(i);
            }

            for (let i = 1; i <=31; i++){
                this.days.push(i);
            }

            const initData = window.profile_page_data;
            initData.contact.is_company_selected = initData.contact.is_company_selected === "1";

            this.regions = initData.regions;
            this.cartItemsCount = initData.cartItemsCount;
            this.favoritesItemsCount = initData.favoritesItemsCount;
            this.countries = initData.countries;

            this.form = initData.contact;
            this.serverPhone = this.form.phone;
            this.serverMiddlename = this.form.middlename;

            this.serverBirthDay = this.form.birth_day;
            this.serverBirthMonth = this.form.birth_month;
            this.serverBirthYear = this.form.birth_Year;

            //если телефонный код НЕ начинается на '99'
            if (!this.IsItNine){
                //отображаем телефоный номер без телефонного кода
                this.form.phone = initData.contact.phone.replace(this.selectedTelCode, "");
                //запоминаем телефон без кода, чтобы потом сравнить его с телефоном перед сохранением данных
                this.tel_without_code = this.form.phone;
            }

            this.$nextTick(() => this.changed = false)

        },
        mounted: function () {
          this.setMaskPhone();
        },

        computed:{
            bus: () => window.bus,
            
            selectedTelCode(){
                let country_tel_code = this.countries.filter(d => d.code.indexOf(this.form.addressShipping.country) !== -1);
                //возвращаем телефонный код страны без '+'
                return country_tel_code[0].tel_code.substr(1);
            },

            //проверка на то, начинается ли телефонный код на '99'
            /**
             * @return {boolean}
             */
            IsItNine(){
               let ninety_nine = this.selectedTelCode.substr(0,2);
               return ninety_nine === "99";
            },
        },
        methods: {

            showSupportBlock(field){
                return 'Для изменения '+ field +' необходимо написать на <a href="mailto:info@ajs.su">info@ajs.su</a>'
            },

            setMaskPhone(){
                //Если Россия или Казахстан
                if (this.form.addressShipping.country === 'rus' || this.form.addressShipping.country === 'kaz') {
                    Inputmask({"mask": "+7 (999) 999-9999"}).mask(this.$refs.phone);
                }
                //Иначе, если код начинается на '99' то отображаем маску без телефонного кода
                else if (this.IsItNine) {
                    Inputmask({"mask": "+999 (99) 999-9999"}).mask(this.$refs.phone);
                }
                else {
                    Inputmask("+" + this.selectedTelCode + " (99) 999-9999").mask(this.$refs.phone);
                }
            },

            sendConfirm() {
                this.confirmSended = true

                window.jQuery.get('/ajs/email-confirm/')
            },

            getCsrf() {
                const tags = document.getElementsByName('_csrf')

                if (tags.length) {
                    return tags[0].value
                }

                throw new Error('TAG with _csrf value not found')
            },

            save() {

                let phone = this.form.phone;

                //Если телефон не редактировали (и он так и остался без кода), то добавляем его
                if (this.tel_without_code === phone)
                {
                    phone = '+' + this.selectedTelCode + this.tel_without_code;
                    this.form.phone = phone;
                }

                let number_amount= phone.replace(/\D+/g,'').length;

               if (this.form.addressShipping.country === 'rus' || this.form.addressShipping.country === 'kaz') {

                    if (number_amount<11){
                        return this.bus.alert('Необходимо указать телефон');
                    }
                }

                else {
                    if (number_amount<12){
                        return this.bus.alert('Необходимо указать телефон');
                    }
                }


                if (!this.form.lastname) return this.bus.alert('Необходимо указать Фамилию');
                if (!this.form.firstname) return this.bus.alert('Необходимо указать Имя');
                if (!this.form.middlename) return this.bus.alert('Необходимо указать Отчество');

                if (!this.form.birth_day) return this.bus.alert('Необходимо указать ДЕНЬ вашего рождения');
                if (!this.form.birth_month) return this.bus.alert('Необходимо указать МЕСЯЦ вашего рождения');
                if (!this.form.birth_year) return this.bus.alert('Необходимо указать ГОД вашего рождения');

                if (this.serverBirthDay && this.serverBirthDay !== '' && this.form.birth_day !== this.serverBirthDay){
                    this.form.birth_day = this.serverBirthDay;
                    return this.bus.alert(this.showSupportBlock('дня рождения'));
                }
                if (this.serverBirthMonth && this.serverBirthMonth !== '' && this.form.birth_month !== this.serverBirthMonth) {
                    this.form.birth_month = this.serverBirthMonth;
                    return this.bus.alert(this.showSupportBlock('месяца рождения'));
                }
                if (this.serverBirthYear && this.serverBirthYear !== '' && this.form.birth_year !== this.serverBirthYear) {
                    this.form.birth_year = this.serverBirthYear;
                    return this.bus.alert(this.showSupportBlock('года рождения'))
                }

                if (!this.form.email) return this.bus.alert('Необходимо указать email');

                if (this.form.addressShipping.country !== 'rus')
                    this.form.addressShipping.region = '';
                else {
                    if (!this.form.addressShipping.region) return this.bus.alert('Необходимо выбрать регион.');
                }

                if (this.form.is_company_selected) {
                    if (!this.form.company) return this.bus.alert ('Поле Название организации обязательно для заполнения.');
                    if (!this.form.inn) return this.bus.alert ('Поле ИНН обязательно для заполнения.');
                    if (!this.form.rs) return this.bus.alert ('Поле Расчетный счет обязательно для заполнения.');
                    if (!this.form.korschet) return this.bus.alert ('Поле Корреспондентский счет обязательно для заполнения.');
                    if (!this.form.bank) return this.bus.alert ('Поле Наименование банка обязательно для заполнения.');
                    if (!this.form.bik) return this.bus.alert ('Поле БИК обязательно для заполнения.');

                    const length_inn = this.form.inn.replace(/[^0-9]/g, "").length;
                    if (length_inn !== 10 && length_inn !== 12) {
                        return this.bus.alert('Поле ИНН должно содержать только цифры в количестве 10 или 12 символов.');
                    }
                }

                this.form.is_company_selected = this.form.is_company_selected ? 1 : 0;

                this.wait = true;

                const data = {
                    _csrf: this.getCsrf(),
                    profile: this.form
                };

                window.jQuery.post('/my/profile/', data, (response) => {

                    response = JSON.parse(response);

                    if (response.status === 'ok') {

                        this.changed = false;

                        let tel = response.contact.phone;

                        //если телефонный код НЕ начинается на 99
                        if (!this.IsItNine){
                            //при обновлении данных на странице возвращаем телефон без кода
                            tel = response.contact.phone.replace(this.selectedTelCode, "");
                            //заменяем телефон без кода новым телефоном
                            this.tel_without_code = tel;
                        }

                        response.contact.phone = tel;
                        this.serverPhone = tel;
                        this.serverMiddlename = response.contact.middlename;

                        this.serverBirthDay = response.contact.birth_day;
                        this.serverBirthMonth = response.contact.birth_month;
                        this.serverBirthYear = response.contact.birth_year;

                        this.form = response.contact;
                        this.form.is_company_selected = this.form.is_company_selected === "1"

                    } else {

                        for (let key in response.errors) {
                            let item = response.errors[key]

                            if (typeof item === 'string') {
                                this.bus.message(`${key} - ${item}`)
                            }

                            if (typeof item === 'object') {
                                this.bus.message(`${key} - ` + item.join('; '))
                            }
                        }
                    }

                    this.wait = false
                });
            }
        }
    }
</script>