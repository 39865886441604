<template>

    <div class="cart-group">

        <div class="cart-group__header">
            <div class="cart-group__header-item">Склад</div>
            <div class="cart-group__header-item">Товарные позиции</div>
            <div class="cart-group__header-item">Цена</div>
            <div class="cart-group__header-item">Кол-во</div>
            <div class="cart-group__header-item">Стоимость</div>
        </div>

        <div class="cart-group__container">

            <div class="cart-group__stock-info">
                <span>Со склада:</span>
                <br class="d-none d-sm-inline">
                <span>
                    <i class="dot-icon dot-icon_color_green ml-3 mr-2"></i>
                    <span class="text text_color_green text_size_lg">{{ cart.stock.name }}</span>
                </span>
            </div>

            <div class="cart-group__content">
                <div class="cart-group__items-wrapper">
                    <template v-for="(item, index) in cart.items" :key="item.id">

                        <!-- Позиция корзины -->
                        <div class="cart-group__item">
                            <div class="cart-group__item-td-1">
                                <a :href="'/'+item.product.url">
                                    <img class="cart-group__item-image" :src="item.image_url">
                                </a>
                            </div>

                            <div class="cart-group__item-td-2 cart-group__item-center">
                                <span>
                                    <a :href="'/'+ item.product.url">
                                        <span class="cart-item-name text text_color_green">{{ item.name }}</span>
                                        <span class="text text_color_gray">
                                            [{{ item.sku }}] {{ getStockNameById(cart.stock_id) }}
                                        </span>
                                    </a>
                                    <ul v-if="item.is_set" style="border-top: 1px #c6c6c8 solid;">
                                        <li v-for="(i,index) in item.set_items" :key="index">
                                            <a :href="'/'+ i.url">
                                                {{i.name}} {{i.quantity}}
                                            </a>
                                        </li>
                                    </ul>
                                </span>
                            </div>

                            <div class="cart-group__item-td-3">
                                <span class="text text_color_green">{{ Number(item.price).priceFormat(2) }} руб.</span>
                            </div>

                            <div class="cart-group__item-td-4 cart-group__item-center">
                                <span>
                                    <input
                                            class="cart-group__item-number input"
                                            :value="item.quantity"
                                            @input="changeCount(item, $event)"
                                            type="number"
                                            min="1"
                                            v-on:focus="quantityInput_focus"
                                            v-on:blur="quantityInput_blur"
                                    > шт.
                                </span>
                            </div>

                            <div class="cart-group__item-td-5 cart-group__item-center">
                                <span class="text">{{ Number(item.full_price).priceFormat(2) }} руб.</span>
                            </div>

                            <div class="cart-group__item-td-6 cart-group__item-center">
                                <button id="delete-item" type="button" @click="delCartItem(item, index)" class="button_small button_color_red">
                                    X
                                </button>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>


        <div class="cart-group__footer">

            <!--купон-->
            <div class="mb-2 mt-3" v-if="isAuth">
                <form method="post" :action="'/cart/?nativeCartCode=' + cart.native_code">
                    <input type="text" name="coupon_code" class="input input_width_150 mr-2" placeholder="Купон" required>
                    <button type="submit" class="button button_color_green">Применить</button>
                </form>
            </div>

            <div class="cart-group__footer-total-and-price mt-3">

                <div class="d-inline-block mr-4">
                    <span class="text text_transform_upper text_color_gray text_size_lg">Скидка:</span>
                </div>

                <div class="d-inline-block mr-4">
                    <span class="text text_color_gray">

                        <span class="text_transform_upper text_weight_bold text_size_lg">
                            {{ Number(cart.total_discount).priceFormat(2) }}
                        </span>
                        <span class="text text_color_gray text_size_lg"> руб.</span>

                        <template v-if="cart.coupon_discount">
                            (из них {{ Number(cart.coupon_discount).priceFormat(2) }} руб. по купону)
                        </template>
                    </span>
                </div>

                <div class="d-inline-block mr-4">
                    <span id="cart-total" class="text text_transform_upper text_color_gray text_size_lg">Итого:</span>
                </div>

                <div class="d-inline-block mr-4">
                    <span class="text text_color_gray text_weight_bold text_size_lg">
                        {{ Number(cart.total).priceFormat(2) }}
                    </span>
                    <span class="text text_color_gray text_size_lg"> руб.</span>
                </div>
            </div>

            <a v-if="!close_message" :href="'/checkout/?nativeCartCode=' + cart.native_code" id="checkout" class="button button_color_green button_mobile-block">
                <span class="mx-4" v-if="isAuth">Оформить заказ</span>
                <span class="mx-4" v-if="!isAuth">Оформить заказ без регистрации</span>
            </a>
            <a v-else class="button button_color_green button_mobile-block" id="checkout" @click="showMessage">
                <span class="mx-4" v-if="isAuth">Оформить заказ</span>
                <span class="mx-4" v-if="!isAuth">Оформить заказ без регистрации</span>
            </a>

            <div class="cart-group__footer" v-if="!isAuth">
                <p>
                    <span>Для получения бонусов и дополнительных скидок по заказу</span>
                    <a href="/login/" @click="setCookie"> Войдите</a>
                    <span> или</span>
                    <a href="/signup/" @click="setCookie"> Зарегистрируетесь</a>
                    <span>.</span>
                </p>
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        props: [
            'cart'
        ],

         data: () => ({
             bonus: 0,
             close_message: null
         }),

        created() {
            this.close_message = JSON.parse(document.getElementById('close_message').value);
        },

        computed: {

            bus: () => window.bus,

            isAuth() {
                return window.wa_is_auth
            },

            bonuses() {
                return Number(
                    String(this.cart.cart_bonuses)
                        .replace(/[^0-9,]/g, '')
                        .replace(',', '.')
                ).priceFormat(2)
            }
        },

        methods: {
            showMessage(){
                return this.bus.alert(this.close_message)
            },

            getStockNameById(stockId) {
                const stock = window.shop_stocks.find(s => Number(s.id) === Number(stockId))

                if (stock) {
                    return stock.name
                }

                return ''
            },

            async changeCount(item, event) {

                const response = await window.api.changeCartItemCount(this.cart.native_code, item.id, event.target.value)

                if (response.error) {
                    alert(response.error)
                }
            },

            async delCartItem(item) {

                const response = await window.api.delCartItem(this.cart.native_code, item.id)

                if (response.error) {
                    alert(response.error)
                }

                this.reload()
            },

            reload() {
                window.location.reload()
            },

            setCookie() {
                window.Cookies.set('url_redirect_after_login', '/cart/')
            }
        }
    }
</script>