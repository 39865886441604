<style>
    .scroller-at-bottom {
        display: block !important;
        height: auto !important;
        width: auto !important;
    }

    img.responsive-image.preview-box {
        max-width: 100% !important;
        margin-bottom: 30px;
    }

    .thumb-list {
        width: 100% !important;
        height: auto !important;
        display: flex !important;
        justify-content: space-between;
        overflow-x: auto;
    }

    #minimal-images .thumb-list {
        justify-content: flex-start;
        padding: 0 2px;
    }

    #minimal-images .thumb-list img.zoomer-control.responsive-image {
        display: none;
    }

    .pane-container {
        width: 100% !important;
        height: 100% !important;
        top: 0 !important;
        z-index: 19 !important;
    }

    .thumb-list img.responsive-image {
        height: 70px !important;
        width: 70px !important;
        object-fit: cover;
        margin: 0 .5rem .5rem 0;
    }

    .thumb-list img.zoomer-control.responsive-image {
        height: 30px !important;
        width: 30px !important;
        margin: unset;
    }

    @media (min-width: 0) and (max-width: 740px) {
        .thumb-list img.responsive-image {
            height: 50px !important;
            width: 50px !important;
        }
    }

    .gallery-wrapper {
        position: fixed !important;
        background: rgb(0, 0, 0);
    }
</style>
<template>
    <div v-if="images">
        <div class="container">
            <div id="minimal-images">
                <div class="scroller-at-bottom">

                    <Gallery
                            ref="galleryRef"
                            :imgSrcList="galleryImages"
                    ></Gallery>
                    <img class="responsive-image preview-box" :src="images[0].href"
                         @click="openGallery(0)">
                    <div class="thumb-list">
                        <img class="responsive-image"
                             v-for="(image, imageIndex) in images"
                             :src="image.thumbnail"
                             :key="imageIndex"
                             @click="openGallery(imageIndex)"
                        >
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import 'gallery-vue3/dist/gallery-vue3.css';
    import Gallery from 'gallery-vue3';
    //import Panzoom from '@panzoom/panzoom'

    export default {

        components: {Gallery},

        created() {
            this.product = JSON.parse(document.getElementById('product').value);
        },

        computed: {

            images() {
                if (this.productImages) {

                    let images = [];

                    this.productImages.map(img => {
                        if (img.url_crop) {
                            images.push({
                                href: img.full_image,
                                thumbnail: img.url_crop
                            });
                        }
                    });
                    return images;
                }
                return null;
            },

            galleryImages() {
                if (this.productImages) {

                    let images = [];

                    this.productImages.map(img => {
                        if (img.url_crop) {
                            images.push(
                                img.full_image
                            );
                        }
                    });
                    return images;
                }
                return null;
            },

            productImages() {
                return this.product.images
            },
        },

        methods: {
            openGallery(index) {
                this.$refs.galleryRef.openGallery(index); //You can indicate starting image index by giving parameter for openGallery(1)
                /*
                let elems = document.getElementsByClassName('gallery');
                for (let i = 0; i < this.galleryImages.length; i++) {
                    console.log(elems[i]);
                    console.log(elems[i].children[0]);
                }

                  const panzoom = Panzoom(elems[0], {
                   maxScale: 5
                });
                panzoom.pan(10, 10)
                 */
            },
        },

        data: () => ({
            product: null,
            index: null
        }),
    }
</script>