<template>
    <div id="search-popover-window" class="popover-window header-popover header-popover-search"
         :class="{'d-none': !showSearch}" @click.stop>
        <div class="popover-window__wrapper shadow">
            <h3 class="mb-3">Результаты поиска</h3>

            <div class="container">
                <div class="row">
                    <div class="col-12 mb-2" v-for="p in products" :key="p.id">
<!--                        {{ JSON.stringify(p) }} -->
                        <div class="header-popover-search__product product-card product-card_border product-card_row p-2">

                            <div class="product-card__image-wrapper">
                                <a :href="p.frontend_url">
                                    <img class="product-card__image" :src="p.image_url">
                                </a>
                            </div>

                            <div class="product-card__info-wrapper">
                                <a :href="p.frontend_url">

                                    <div class="product-card__prices block-left-right">
                                        <div>
                                           <!-- <template v-if="p.unconverted_min_price != p.unconverted_max_price">
                                                {{ Number(p.unconverted_min_price).priceFormat(2) }} - {{ Number(p.unconverted_max_price).priceFormat(2) }}
                                            </template>
                                            <template v-else>{{ Number(p.unconverted_max_price).priceFormat(2) }}</template>-->
                                            <span class="text text_color_green">
                                                {{Number(p.price).priceFormat(2)}} <span class="rubl">p</span>
                                            </span>
                                            <template v-if="p.crossed_out_price">
                                                &nbsp;&nbsp;
                                                <span class="text text_color_gray text_decorator_through">
                                                   {{Number(p.crossed_out_price).priceFormat(2)}}&nbsp;<span class="rubl">p</span>
                                                </span>
                                            </template>
                                        </div>
                                    </div>

                                    <p class="product-card__description">
                                        {{ p.name }}
                                    </p>

                                    <div class="product-card__statistic block-left-right">

                                        <!-- Не понятано как он считает количество заказоа, показывает нереальные цифры, поэтому закоментировал -->
                                        <!--<div class="text text_color_gray product-card__orders-count">-->
                                            <!--{{ p.total_sales }} заказов-->
                                        <!--</div>-->

                                       <!-- <stars :rate="p.rating"/>-->
                                    </div>
                                </a>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import debounce from 'lodash/debounce'
    import {objectToArray} from "../common";

    const cancelToken = axios.CancelToken;
    let previousRequest = null

    export default {
        computed: {
            bus: () => window.bus,

            showSearch(){
                return this.bus.showSearch.value
            },
        },

        mounted() {
            const el = document.getElementById('search-input')

            el.oninput = debounce(() => this.onChange(el.value), 300)
        },

        data: () => ({
            query: '',
            products: []
        }),

        methods: {

            async onChange(query) {

                if (!query || query.length < 2) {
                    return
                }

                if (previousRequest) {
                    previousRequest()
                    previousRequest = null
                }

                const params = {
                    cancelToken: new cancelToken(c => previousRequest = c)
                }

                const {data} = await axios.get(`/search/ajs/?query=${query}`, params)

                if (data.status === 'ok') {

                    this.products = objectToArray(data.data.products)

                    this.bus.showSearch.value = true
                }
            }
        }
    }
</script>