<template xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div class="header-catalog shadow" v-if="showCatalog" @click.stop>
        <div class="pt-2">
            <div style="position: relative;width: 100%;padding-right: 15px;padding-left: 35px;height: 50px;">
                <div>
                    <span v-if="divCount != 2">Группировка: </span>
                    <a href="javascript:void(0)" style="color: #00683e;" @click="groupToggle">{{groupByLink}}</a>

                    <input
                            ref="filterStr"
                            v-focus class="ml-3 col-lg-4 col-sm-8 col-6 input"
                            v-model="filterStr"
                            type="text"
                            placeholder="Поиск"
                            v-on:focus="filterStr_focus"
                            v-on:blur="filterStr_blur"
                    >

                    <button type="button" @click="clearFilter" class="ml-1 button_small button_color_green" title="Очистить фильтр">
                        X
                    </button>

                </div>
            </div>
            <div class="header-catalog__container">
                <div class="mx-4 row">

                    <template v-if="alphabetGroup">
                        <div class="col-6 col-md-3 col-lg-2 pb-3" v-for="(columns, index) in alphaCategories" :key="index">
                            <div v-for="(group, g_index) in columns" :key="g_index">
                                <span class="header-catalog__top-category text_size_lg mb-4">
                                {{ group.sym }}
                                </span>

                                <div v-for="(item, item_ind) in group.items" :key="item_ind">

                                    <template v-if="item.font=='1'">
                                        <a style="font-weight:bolder" :href="item.url">
                                            {{ item.name }}
                                        </a>
                                    </template>

                                    <template v-else>
                                        <a :href="item.url">
                                            {{ item.name }}
                                        </a>
                                    </template>

                                </div>

                                <div class="hr mt-1 mb-2"></div>
                            </div>

                        </div>
                    </template>

                    <template v-else-if="categoryGroup">
                        <div class="col-6 col-md-3 col-lg-2 pb-3" :key="category.id" v-for="category in categories">
                            <a :href="category.url" class="header-catalog__top-category mb-4">
                                {{ category.name }}
                            </a>
                            <template v-if="category.childs.length">
                                <div>
                                    <div v-for="child in category.childs" :key="child.id">
                                        <a :href="child.url" class="header-catalog__sub-category">
                                            {{ child.name }}
                                        </a>
                                    </div>
                                </div>
                                <div class="hr mt-1 mb-2"></div>
                            </template>
                        </div>
                    </template>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const BY_ALPHABET = 'По Алфавиту';
    const BY_CATEGORY = 'По Категориям';
    const SELECTED_GROUP_TYPE_KEY = 'main_catalog_group_type';

    export default {

        created() {
            this.cats = JSON.parse(document.getElementById('categories').value)
            let el = document.getElementById('categories');
            el.parentNode.removeChild(el);

            this.restoreGroupType()
        },

        directives: {
            focus: {
                inserted: function (el) {
                      el.focus();
                }
            }
        },

        mounted() {
            this.onResizeWindowHandler = () => this.onChangeWindowWidth($(window).width())
            this.onResizeWindowHandler()

            window.addEventListener('resize', this.onResizeWindowHandler)
        },

        beforeUnmount() {
            window.removeEventListener('resize', this.onResizeWindowHandler)
        },

        data: function () {
            return {
                sortParam: '',
                filterStr: '',
                divCount: 6,                      // количество столбцов
                groupByLink: BY_ALPHABET,         // link для переключения группировки каталога
                alphabetGroup: false,
                categoryGroup: true
            }
        },

        methods: {
            filterStr_focus() {
                $("body #umnico-widget-wrapper").hide();
            },

            filterStr_blur() {
                $("body #umnico-widget-wrapper").show();
            },

            groupToggle() {
                this.filterStr = '';
                this.categoryGroup = false;
                this.alphabetGroup = false;
                if (this.groupByLink === BY_CATEGORY) {
                    this.groupByLink = BY_ALPHABET;
                    this.categoryGroup = true;
                } else {
                    this.groupByLink = BY_CATEGORY;
                    this.alphabetGroup = true;
                }
                window.localStorage.setItem(SELECTED_GROUP_TYPE_KEY, this.groupByLink)

                $(this.$refs.filterStr).focus(); // через jQuery делаем focus на элемент, у которого атрибут refs = filterStr
            },

            clearFilter() {
                this.filterStr = '';
                $(this.$refs.filterStr).focus(); // через jQuery делаем focus на элемент, у которого атрибут refs = filterStr
            },

            restoreGroupType() {
                this.groupByLink = window.localStorage.getItem(SELECTED_GROUP_TYPE_KEY)
                this.categoryGroup = false;
                this.alphabetGroup = false;

                if (this.groupByLink === BY_ALPHABET) {
                    this.categoryGroup = true

                } else if (this.groupByLink === BY_CATEGORY) {
                    this.alphabetGroup = true

                } else {
                    this.groupByLink = BY_ALPHABET
                    this.categoryGroup = true
                }
            },

            // Получение плоского отсортированного списка категорий
            plainCategories() {

                function ucFirst(s) {
                    return s.charAt(0).toUpperCase() + s.substr(1);
                }

                let lis = this.cats;
                let result = [];

                for (let i = 0; i < lis.length - 1; i++) {

                    let name1 = ucFirst(lis[i].name); // Первая буква названия категории в верхний регистр
                    result.push({
                        sym: name1.substr(0, 1), name: name1, url: lis[i].url, font: 1
                    });

                    let l2 = lis[i].childs;
                    if (l2.length > 0) {
                        for (let j = 0; j < l2.length - 1; j++) {

                            let name2 = ucFirst(l2[j].name); // Первая буква названия категории в верхний регистр
                            result.push({
                                sym: name2.substr(0, 1), name: name2, url: l2[j].url, font: 2
                            });

                            let l3 = l2[j].childs;
                            if (l3.length > 0) {

                                for (let k = 0; k < l3.length - 1; k++) {
                                    let name3 = ucFirst(l3[k].name); // Первая буква названия категории в верхний регистр
                                    result.push({
                                        sym: name3.substr(0, 1), name: name3 + ' (' + name2 +')', url: l3[k].url, font: 3
                                    });
                                }
                            }
                        }
                    }
                }

                result.sort((d1, d2) => {return d1.name.toLowerCase() > d2.name.toLowerCase() ? 1 : -1});

                return result.filter((item) => {return item.name.toLowerCase().indexOf(this.filterStr.toLowerCase()) > -1});
            },

            // копия для массива категорий необходима, что бы при фильтрации не удалялись элементы из основного массива
            copyCategories() {

                function copy(arr) {
                    return arr.map((item) => {
                        if (item.childs.length > 0)
                            item.childs = copy(item.childs);
                        return Object.assign({}, item);
                    });
                }

                return copy(this.cats);

            },


            onChangeWindowWidth(width) {
                // sizes can view in wa-apps/shop/themes/ajs/node/src/scss/definitions.scss
                // col-6
                this.divCount = 2

                // col-md-3
                if (width >= 768) this.divCount = 4

                // col-lg-2
                if (width >= 992) this.divCount = 6
            }
        },

        computed: {
            bus: () => window.bus,

            showCatalog(){
                return this.bus.showCatalog.value
            },

            categories() {
                if (this.filterStr != '') {
                    let categories = this.copyCategories();

                    return categories.filter((item) => {
                        if (item.name.toLowerCase().indexOf(this.filterStr.toLowerCase()) > -1)
                            return true;

                        item.childs = item.childs.filter((itemchild) => {
                            return itemchild.name.toLowerCase().indexOf(this.filterStr.toLowerCase()) > -1;
                        });
                        return item.childs.length > 0;

                    });
                } else
                    return this.cats;
            },

            alphaCategories() {
                let cats = this.plainCategories();

                // Количество категорий, отображаемых в одной колонке
                let catPerCol = Math.floor((cats.length) / this.divCount)+1;

                let columns = []; // массив колонок
                let column = [];  // текущая колонка
                let group = {sym: '', items: []}; // текущая группа
                let sym = ''; // текущая буква

                // цикл по категориям
                cats.forEach((item, i) => {

                    // если елемент массива кратен количеству элементов в массивве
                    if (i % catPerCol == 0) {
                        // создвем новую колонку и запихиваем ее в массив columns
                        column = [];
                        columns.push(column);

                        // в созданную колонку сразу добавляем новую группу
                        if ((sym != item.sym)) {
                            sym = item.sym;
                            group = {sym: sym, items: []}; // если еще произошла смена буквы
                        } else {
                            group = {sym: '', items: []};  // если буква не поменядась
                        }
                        column.push(group);

                    // если изменилась буква
                    } else if (sym != item.sym) {
                        // запоминаем новую букву, создаем новую группу и добавляем ее в колонку
                        sym = item.sym;
                        group = {sym: sym, items: []};
                        column.push(group);
                    }
                    group.items.push(item);
                });
                return columns;
            },

        },
    }

</script>

