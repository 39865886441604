<template>
    <div class="modal__wrapper w-100">
        <div class="modal__header p-2">
            <a href="javascript:void(0)" @click="back()" class="modal__close">
                <button class="modal__close_button">Назад</button>
            </a>
        </div>
        <div class="modal__content">
            <div id="map" style="height:100%"></div>
        </div>
    </div>
</template>

<script>
    export default {
        created(){
            const city = JSON.parse(document.getElementById('city').value);
            if (city){
                ymaps.ready(function () {
                    var myMap = new ymaps.Map('map', {
                            center: [city.lat, city.long],
                            zoom: 16
                        }, {
                            searchControlProvider: 'yandex#search'
                        }),

                        myPlacemark = new ymaps.Placemark(myMap.getCenter(), {
                            iconCaption: city.name,
                            balloonContent: city.address
                        },{
                            preset: 'islands#greenDotIconWithCaption',
                            iconCaptionMaxWidth: '400'
                        });
                    myMap.geoObjects
                        .add(myPlacemark)
                });
            }
        },
        methods: {
            back(){
                window.history.back();
            }
        }
    }
</script>
