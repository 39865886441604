<template>
    <div class="popover-window header-popover header-popover-cart" v-if="showCart" @click.stop>
        <div class="popover-window__wrapper shadow text-left d-flex flex-column">
            <div class="container header-popover-cart__items-wrapper">

                <template v-if="!cart.items.length">
                    <div class="text-center mt-2">
                        <h3>Корзина пуста</h3>
                    </div>
                </template>

                <template v-else>
                    <div class="product-card product-card_row p-2" v-for="item in cart.items" :key="item.id">
                        <div class="product-card__image-wrapper">
                            <a href="#">
                                <img class="product-card__image" :src="item.image_src">
                            </a>
                        </div>

                        <div class="product-card__info-wrapper">
                            <div class="product-card__prices block-left-right">
                                <div class="text text_color_green">
                                    {{ Number(item.price).priceFormat(2) }} руб. * {{ item.quantity }} шт.
                                </div>
                                <!--<div class="text text_color_gray text_decorator_through">{{ item.price }} руб.</div>-->
                            </div>

                            <p class="product-card__description">
                                {{ item.name }}
                                <span class="text text_color_gray"> [{{ item.sku }}]</span>
                                <span class="text text_color_gray"> {{ getStockNameById(item.stock_id) }}</span>
                            </p>

                            <!--<div class="text-right text text_color_gray">-->
                            <!--Кол-во: {{ item.quantity }}-->
                            <!--</div>-->
                        </div>
                    </div>
                </template>

            </div>

            <div v-if="cart.items.length">
                <div class="hr hr_color_black"></div>

                <div class="container">
                    <div class="my-2">
                        <span class="text text_color_gray mr-2">Итого:</span>
                        <span>{{ Number(cart.total).priceFormat(2) }} руб.</span>
                    </div>

                    <div class="row my-3">
                        <div class="col-12 col-sm-6">
                            <a href="/cart/" class="button button_color_green button_outline button_block">
                                <span class="text text_size_desktop-xl">Смотреть корзину</span>
                            </a>
                        </div>
                        <!--<div class="col-6">-->
                        <!--<a href="/checkout/" class="button button_color_green button_block">-->
                        <!--<span class="text text_size_desktop-xl d-none d-sm-block">Оформить заказ</span>-->
                        <!--<span class="text text_size_desktop-xl d-sm-none">Оформить</span>-->
                        <!--</a>-->
                        <!--</div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        async created() {
            this.bus.refreshCartInfo()
        },

        computed: {
            bus: () => window.bus,
            showCart(){
                return this.bus.showCart.value
            },
            cart(){
                return this.bus.cart.value
            }
        },

        methods: {
            getStockNameById(stockId) {
                const stock = window.shop_stocks.find(s => Number(s.id) === Number(stockId))

                if (stock) {
                    return stock.name
                }

                return ''
            }
        }
    }
</script>