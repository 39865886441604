<template>
    <div>
        <div class="md-modal-msg md-effect-1 md-show" v-if="showModal">
            <div class="md-content info">
                <button v-if="closeButton" class="md-close" @click="closeModal"></button>
                <h1 v-html="title"></h1>
                <div v-html="text"></div>
            </div>
        </div>
        <div class="md-overlay" @click="closeModal"></div>
    </div>
</template>

<script>
    export default {
        computed: {
            bus: () => window.bus,
            showModal(){
              return this.bus.showModal.value
            },
            title() {
                return this.bus.modalTitle.value
            },
            text() {
                return this.bus.modalText.value
            },
            closeButton() {
                return this.bus.modalCloseButton.value
            },
        },
        methods: {
            closeModal() {
                this.bus.showModal.value = false
            }
        },
    }
</script>
