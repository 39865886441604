import { ref } from 'vue'

export default {

    showNav: ref(false),
    showCatalog: ref(false),
    showCart: ref(false),
    showAccount: ref(false),
    showFavorites: ref(false),
    showFilter: ref(false),
    showSearch: ref(false),

    //модальное окно
    showModal: ref(false),
    modalText: ref(''),
    modalTitle: ref(''),
    modalCloseButton: ref(true),

    cart: ref({
        items: [],
        itemsCount: 0,
        total: 0
    }),

    favorites: ref([]),

    clickFilter() {
        this.showFilter.value = !this.showFilter.value;
    },

    alert(text){
        this.modalTitle.value = text;
        this.modalText.value = '';
        this.showModal.value = true;
    },

    message(text){
        this.modalTitle.value = '';
        this.modalText.value = text;
        this.showModal.value = true;
    },


    clickMobileNav() {
        if (this.showNav.value) {
            this.outsideClick()
        } else {
            this.outsideClick()
            this.showNav.value = true
        }

        this.imperativeStyle()
    },

    clickCart() {
        if (this.showCart.value) {
            this.outsideClick()
        } else {
            this.outsideClick()
            this.showCart.value = true;
        }
    },

    clickAccount() {
        if (this.showAccount.value) {
            this.outsideClick()
        } else {
            this.outsideClick()
            this.showAccount.value = true;
        }
    },

    clickFavorites() {
        if (this.showFavorites.value) {
            this.outsideClick()
        } else {
            this.outsideClick()
            this.showFavorites.value = true
        }

        this.refreshFavorites()
    },

    outsideClick() {
        this.showNav.value = false
        this.showCart.value = false
        this.showCatalog.value = false
        this.showAccount.value = false
        this.showFavorites.value = false
        this.showSearch.value = false

        this.imperativeStyle()
    },

    imperativeStyle() {

        window.jQuery('.header__menu-item').removeClass('header__menu-item_active')

        // Если показывается каталог
        if (this.showCatalog.value) {

            if (this.showCatalog.value === 'by-categories') {
                window.jQuery('.header__menu-item_by-categories').addClass('header__menu-item_active')
            }

            // Скрываем скролл страницы
            $('body').css('overflow-y', 'hidden')
        } else {
            $('body').css('overflow-y', 'auto')
        }

        if (this.showNav.value) {
            window.jQuery('.header__mobile-menu-wrapper').addClass('header__mobile-menu-wrapper_active')
        } else {
            window.jQuery('.header__mobile-menu-wrapper').removeClass('header__mobile-menu-wrapper_active')
        }

        // Избранное
        if (this.favorites.value.length) {
            $('.fa-star.header__icon').removeClass('far');
            $('.fa-star.header__icon').addClass('fas');
        } else {
            $('.fa-star.header__icon').addClass('far');
            $('.fa-star.header__icon').removeClass('fas');
        }
    },

    async refreshCartInfo() {
        const response = await window.api.getCartInfo()

        this.cart.value.total= response.total
        this.cart.value.items = response.items
        this.cart.value.itemsCount = Number(response.itemsCount)

        if (this.cart.value.itemsCount) {
            window.jQuery('.header__icon_counter').show()
            window.jQuery('.header__icon_counter').text(this.cart.value.itemsCount)
        } else {
            window.jQuery('.header__icon_counter').hide()
        }
    },

    clickCatalog(type) {
        if (this.showCatalog.value) {
            this.outsideClick()
        } else {
            this.outsideClick()
            this.showCatalog.value = type;
        }

        this.imperativeStyle()
    },

    refreshFavorites() {
        $.get('/ajs/favorites', ({data}) => {

            if (data.products) {

                try {
                    this.favorites.value = Object.values(data.products)
                } catch (e) {
                    console.error(e)
                    this.favorites.value = []
                }
            }

            this.imperativeStyle()
        })
    },

}