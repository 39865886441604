<template>
    <div class="header-mobile-nav" v-if="showNav" @click.stop>
        <div class="header-mobile-nav__wrapper shadow p-2">

            <div class="header-mobile-nav__search-wrapper mb-4">
                <input type="text" class="input" placeholder="Поиск" v-model="searchStr" @keyup.enter="openSearch">
                <a href="javascript:void(0)" @click="openSearch"><i class="header-mobile-nav__search-icon fas fa-search"></i></a>
            </div>

            <nav class="header__menu">
                <a
                        href="/" class="header__menu-item"
                        :class="{'header__menu-item_active': showCatalog === 'by-categories'}"
                        @click.stop.prevent="bus.clickCatalog">
                    Каталог
                </a>
                <!--<a href="/reviewsplus/allreviews" class="header__menu-item">Отзывы</a>-->
                <a href="/news/" class="header__menu-item">Новости</a>
                <a href="https://blog.ajs.su" class="header__menu-item">Блог</a>
                <a href="/contact/" class="header__menu-item">Контакты</a>
            </nav>

            <div class="header-mobile-nav__bottom text_transform_upper p-2">
                <p class="text_color_yellow">Сеть магазинов автомобильного тюнинга</p>
                <span class="text_color_white">
                    8-800-550-44-40<br><span class="text_size_sm">Звонки по России бесплатные</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            bus: () => window.bus,
            showNav(){
                return this.bus.showNav.value
            },
            showCatalog(){
                return this.bus.showCatalog.value
            },
        },

        data: () => ({
            searchStr: ''
        }),

        methods: {
            openSearch() {
                window.location.href = '/search/?query=' + (encodeURI ? encodeURI(this.searchStr) : this.searchStr)
            }
        }
    }
</script>