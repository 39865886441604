<template>
    <div>
        <div class="product-page__hr my-4 my-sm-2"></div>

        <!--Стоимость-->
        <div class="mb-3" style="display: flex; align-items: center">
            <span v-if="costStr" id="product-price" class="text text_size_xl text_weight_bold text_color_green mr-2">
                {{ costStr }}&nbsp;<span class="rubl">p</span>
            </span>
            <span v-else class="text text_size_xl text_weight_bold text_color_green mr-2">
                Товара нет в наличии
            </span>
            <template v-if="crossedOutPrice">
                &nbsp;&nbsp;
                <span class="text text_size_xl text_color_gray text_decorator_through mr-2">
                    {{ crossedOutPrice }}&nbsp;<span class="rubl">p</span>
                </span>
            </template>
            <img v-if="product.crossed_out_price_exists && costStr" :src="`${waThemeUrl}img/promo.png`">
        </div>

        <!-- Склады -->
        <div ref="stocks" class="mb-3">
            <!--склады desktop-->
            <div v-if="!mobile_view" class="product-page__group-menu">
                <ul class="product-page__group-menu_ul" @click="stocksVisible=!stocksVisible">
                    <li>
                        <a style="border: 0" v-if="!selectedStockId" href="javascript:void(0)">
                             <span class="text text_color_green text_size_xl">
                                Выберите склад
                             </span>
                            <div id="down-triangle" :class="{'open': stocksVisible}"></div>
                        </a>
                        <a v-else href="javascript:void(0)">
                            <span v-if="stockCount(selectedStockId)" class="text text_color_green text_size_lg">
                                {{selectedStockName}}
                            </span>
                            <span v-else class="text text_color_gray text_size_lg">
                                {{selectedStockName}} <b>Нет в наличии</b>
                            </span>
                            <div id="down-triangle" :class="{'open': stocksVisible}"></div>
                        </a>
                        <ul class="product-page__group-menu_ul_ul" v-show="stocksVisible">
                            <template v-for="stock in shopStocks">
                                <template v-if="selectedStockId !== stock.id">
                                    <li :key="stock.id">
                                        <a v-if="stockCount(stock.id) && stockPrice(stock.id)" href="javascript:void(0)"
                                           @click="selectStock(stock)">
                                            <span class="text text_color_green text_size_lg">
                                                {{ stock.name }}  <b>{{stockPrice(stock.id)}}&nbsp;<span class="rubl">p</span></b>
                                            </span>
                                        </a>
                                        <a v-else>
                                            <span class="text text_color_gray text_size_lg">
                                                {{ stock.name }}  <b>Нет в наличии</b>
                                            </span>
                                        </a>
                                    </li>
                                </template>
                            </template>
                        </ul>
                    </li>
                </ul>
            </div>

            <!--склады mobile-->
            <select v-else class="product-page__group-menu" v-model="selectedStockId" @change="changeStock($event)">
                <option v-if="!selectedStockId" :value="null" disabled>
                    Выберите склад
                </option>
                <template v-for="stock in shopStocks" :key="stock.id">
                        <option v-if="stockCount(stock.id) && stockPrice(stock.id)" :value="stock.id">
                            {{ stock.name }} <b>{{stockPrice(stock.id)}}&nbsp; ₽</b>
                        </option>
                        <option v-else :value="null" disabled>{{ stock.name }} <b>Нет в наличии</b></option>
                </template>
            </select>

        </div>

        <!-- Варианты (показываем выбор вариантов только если их больше 1) -->
        <template v-if="skus.length > 1">
            <div class="mb-2">
                <span class="text text_color_black text_size_md">Варианты:</span>
            </div>
            <div class="product-page__properties variants mb-3">
                <!----Если выбрали склад----->
                <div v-if="selectedStockId" class="product-page__container variants">
                    <template v-for="sku in skusWithSelectedStock">
                        <!--варианты (пустые варианты не показываем)-->
                        <div v-if="sku.price_in_stock && sku.count_in_stock && sku.name"
                             class="product-page__option"
                             :class="{'product-page__option_active': sku.id === selectedSkuId}"
                             :key="sku.id"
                             @click="selectSku(sku)">
                            <span class="text_color_green">{{ sku.name }}</span>
                        </div>
                    </template>
                </div>
                <div v-else class="product-page__container variants">
                    <!----Если склад не выбран, то показываем все варианты с мин. ценой по каждому складу----->
                    <template v-for="(sku,index) in allSkus" :key="index">
                        <div v-if="sku.name" class="product-page__option">
                            <span class="text_color_gray">{{ sku.name }} от {{sku.price}}&nbsp;<span class="rubl">p</span></span>
                        </div>
                    </template>
                </div>
            </div>
        </template>


        <div class="mt-2 mb-3" style="display: flex; align-items: center;">

            <!----Корзина----->
            <div class="product-page__cart-block">

                <div class="changeCount" v-if="selectedSkuInCartCount()">

                    <button class="button button_color_green mr-3" onclick="window.location.href = '/cart/';" :disabled="wait">
                        <span v-if="!wait" class="text text_size_lg">В корзине</span>
                        <span v-else>Подождите...</span>
                    </button>

                    <template v-if="!changeQuantity">
                        <span class="mr-3">{{selectedSkuInCartCount()}} шт.</span>
                        <a class="mr-3" href="javascript:void(0)" @click="changeQuantity = true">Изменить</a>
                    </template>

                    <template v-else>
                        <span>
                            <input
                                    ref="chCount"
                                    :value="changeCountValue"
                                    class="cart-group__item-number input"
                                    type="number"
                                    min="0"
                                    @input="changeCount($event)"
                            > шт.
                        </span>

                        <!--<button v-if="!mobile_view" style="min-width: 35px;" class="button button_color_green ml-3 mr-3"
                                @click="changeCount"
                                :disabled="wait">
                            <span>OK</span>
                        </button>-->
                    </template>
                </div>

                <template v-else>
                    <button id="add-to-cart" class="button button_color_green mr-3" @click="addToCart" :disabled="wait">
                        <img :src="`${waThemeUrl}images/product/white-cart.png`" class="button__icon">
                        <span v-if="wait">Добавляется...</span>
                        <span v-else-if="!mobile_view" class="text text_size_lg">В корзину</span>
                        <span style="font-size: 1.3rem" v-else class="text">В корзину
                            <template v-if="costStr && selectedStockId">
                                за {{ costStr }}&nbsp;<span class="rubl">p</span>
                            </template>
                        </span>
                    </button>
                </template>
            </div>

            <!--------Избранное-------->
            <template v-if="isAuth">
                <a href="javascript:void(0)" @click="addToFavorites" v-if="!product.in_favorites" style="display: flex; align-items: center;">
                    <img :src="`${waThemeUrl}images/product/mini-star.png`" class="icon mr-2">
                    <span class="text text_color_green text_weight_bold text_size_lg">
                        В избранное
                    </span>
                </a>
                <a href="javascript:void(0)" @click="removeFromFavorites" v-if="product.in_favorites" style="display: flex; align-items: center;">
                    <img :src="`${waThemeUrl}images/product/mini-star.png`" class="icon mr-2">
                    <span class="text text_color_green text_weight_bold text_size_lg">
                        Удалить из избранного
                    </span>
                </a>
            </template>
        </div>

        <!--------Отзывы и рейтинг-------->
        <div class="product-page__stat mb-3">
            <span class="text text_color_gray text_size_md">Отзывы: {{ product.rating_count }}</span>
            <span class="text text_color_gray mx-2">|</span>
            <span class="text text_color_gray text_size_md text_weight_bold">Заказы: {{ product.order_count }} </span>
        </div>

    </div>
</template>

<script>
    import { isProxy, toRaw } from 'vue';

    export default {

        created() {
            this.product = JSON.parse(document.getElementById('product').value)

            if (!this.shopStocks || !this.shopStocks.length) {
                throw Error("Список складов пуст")
            }

            if (!this.skus || !this.skus.length) {
                throw Error("Список артикулов пуст")
            }

            // Первый склад, на котором есть остатки
            let firstStockWithExistsProducts = null

            // Первый артикул с остатками
            let existsSkuInStock = null

            this.shopStocks.forEach(stock => {

                if (existsSkuInStock) {
                    return
                }

                const existsSku = this.minSku(stock.id);

                if (existsSku) {
                    existsSkuInStock = existsSku;
                    firstStockWithExistsProducts = stock
                }
            });


            // Всего 1 артикул
            if (this.skus.length === 1) {

                // Вариант 1. Один артикул и нет отстатков
                if (!firstStockWithExistsProducts) {

                    // Выбираем первый (единственный) артикул
                    this.selectedSkuId = +this.skus[0].id
                }

                // Вариант 2. Один артикул и есть остатки
                if (firstStockWithExistsProducts) {

                    // Выбираем первый (единственный) артикул
                    this.selectedSkuId = +existsSkuInStock.id
                }
            }

            // Несколько артикулов
            if (this.skus.length > 1) {

                // Вариант 3. Много артикулов и нет отстатков
                if (!firstStockWithExistsProducts) {
                    // Не выбираем артикул
                    this.selectedSkuId = null
                }

                // Вариант 4. Много артикулов и есть остатки
                if (firstStockWithExistsProducts) {
                    // Выбираем первый артикул с остаткамми артикул
                    this.selectedSkuId = +existsSkuInStock.id
                }
            }


            // Корзина грузится асинхронно
            setTimeout(() => {

                // Если в корзине есть текущий товар, то нужно выбрать его, если для артикула есть остатки
                if (this.cart && this.cart.items && this.cart.items.length) {

                    let selected = false;

                    this.cart.items.forEach((item) => {

                        if (selected) {
                            return
                        }

                        const itemSku = this.skus.find(sku => +sku.id === +item.sku_id);

                        if (!itemSku) {
                            return
                        }

                        const itemStock = itemSku.stocks.find(s => +s.stock_id === +item.stock_id);

                        if (!itemStock) {
                            return
                        }

                        // Если текущая позиция в корзине есть в текущем продукте, и есть остатки, выбираем ее
                        if (itemStock.count) {
                            this.selectedStockId = +itemStock.stock_id;
                            this.selectedSkuId = +itemSku.id;

                            selected = true
                        }
                    })
                }

            }, 1000)
        },

        mounted() {
            this.onResizeWindowHandler = () => this.onChangeWindowWidth($(window).width());
            this.onResizeWindowHandler();
            window.addEventListener('resize', this.onResizeWindowHandler);
        },

        beforeUnmount() {
            window.removeEventListener('resize', this.onResizeWindowHandler)
        },

        computed: {
            bus: () => window.bus,

            cart(){
                return this.bus.cart.value
            },

            isAuth: () => window.wa_is_auth,

            waThemeUrl() {
                return window.wa_theme_url
            },

            shopStocks() {
                return window.shop_stocks
            },

            skus() {
                return this.product.skus
            },

            //артикулы с минимальной ценой
            allSkus() {
                let prices = [];
                let skus = [];
                this.skus
                    .filter(sku => sku.stocks.length)
                    .map((sku) => {
                        sku.stocks.map(stock => {
                            if (stock.count && stock.price > 0) {
                                let p = stock.price;
                                if (typeof (p) === 'number')
                                    p = String(p);
                                p = p.split('.')[0];
                                prices.push(p);
                            }
                        });
                        if (prices.length) {
                            const min = Math.min.apply(null, prices);
                            skus.push({name: sku.name, price: min});
                        }
                        prices = [];
                    });
                return skus;
            },

            /**
             * Минимальная и максиальная цены для всех складов (у которых товары есть в наличии)
             *
             */
            stockMinMaxPrices() {
                let prices = [];
                this.skus
                    .filter(sku => sku.stocks.length)
                    .map((sku) => {
                        sku.stocks.map(stock => {
                            if (stock.count && stock.price > 0) {
                                let p = stock.price;
                                if (typeof (p) === 'number')
                                    p = String(p);
                                p = p.split('.')[0];
                                prices.push(p);
                            }
                        });
                    });
                if (prices.length) {
                    const min = Math.min.apply(null, prices);
                    const max = Math.max.apply(null, prices);
                    if (min !== max)
                        return 'от ' + min + ' до ' + max;
                    else
                        return min
                }
                return null;
            },

            selectedSkuInCart() {
                if (!this.cart.items) {
                    return null
                }
                return this.cart.items.find(i => {
                    return (
                        Number(i.stock_id) === Number(this.selectedStockId)
                        && Number(i.product_id) === Number(this.product.id)
                        && Number(i.sku_id) === Number(this.selectedSkuId)
                    )
                })
            },

            /**
             * Позиции с информацией о текущем складе.
             */
            skusWithSelectedStock() {

                if (this.selectedStockId === null) {
                    //throw Error("Склад не выбран")
                    return null
                }

                return this
                    .skus
                    .filter(sku => sku.stocks.length)
                    .map((sku) => {

                        let selectedStock = sku.stocks.find(stock => Number(stock.stock_id) === Number(this.selectedStockId));

                        if (!selectedStock) {
                            throw Error("Не удалось найти выбранный склад для артикула")
                        }

                        return {
                            id: +sku.id,
                            name: sku.name,
                            stock_id: +this.selectedStockId,
                            count_in_stock: +selectedStock.count,
                            price_in_stock: +selectedStock.price,
                            crossed_out_price: +selectedStock.crossed_out_price
                        }
                    })
            },

            selectedSkuWithSelectedStock() {
                if (!this.selectedSkuId || !this.selectedStockId) {
                    return null
                }

                return this.skusWithSelectedStock.find(s => s.id === this.selectedSkuId)
            },

            // количество товара выбранного артикула на выбранном складе
            selectedSkuWithSelectedStock_count() {
                let stock = this.selectedSkuWithSelectedStock;
                if (!stock)
                    return 0;
                return stock.count_in_stock;
            },

            selectedStockName(){
                const stock = this.shopStocks.filter(ss => ss.id === this.selectedStockId);
                return stock[0].name
            },

            //Стоимость
            costStr() {
                if (this.selectedSkuWithSelectedStock) {
                    return (
                        (this.selectedSkuInCartCount() || 1) * this.selectedSkuWithSelectedStock.price_in_stock
                    ).priceFormat(2)
                } else {
                    return this.stockMinMaxPrices
                }
            },

            //Зачеркнутая цена
            crossedOutPrice(){
                if (this.selectedSkuWithSelectedStock && this.selectedSkuWithSelectedStock.crossed_out_price) {
                    return (
                        (this.selectedSkuInCartCount() || 1) * this.selectedSkuWithSelectedStock.crossed_out_price
                    ).priceFormat(2)
                }
                return null
            },

            bonus() {
                return (
                    Number.parseFloat(this.product.product_bonuses) * (this.icCartCount || 1)
                ).priceFormat(2)
            }
        },

        data: () => ({
            product: null,
            selectedSkuId: null,
            selectedStockId: null,
            wait: false,
            changeQuantity: false,
            changeCountValue: null,
            showcaseVisible: false,
            stocksVisible: false,
            mobile_view: false
        }),

        methods: {
            selectedSkuInCartCount() {
                const inCart = this.selectedSkuInCart;

                if (!inCart) {
                    return null
                }

                this.changeCountValue = Number(inCart.quantity);

                return Number(inCart.quantity)
            },
            /**
             * Наличие товара на складе (есть или нет)
             * (по каждому артикулу)
             */
            stockCount(stock_id) {

                let sum = 0;

                this.skus
                    .filter(sku => sku.stocks.length)

                    .map((sku) => {

                        let selectedStock = sku.stocks.find(stock => Number(stock.stock_id) === Number(stock_id));

                        if (selectedStock && selectedStock.count) {
                            sum++;
                        }

                    });
                return sum > 0;
            },

            /**
             * Минимальная цена для заданного склада
             *
             */
            stockPrice(stock_id) {
                let prices = [];
                this.skus
                    .filter(sku => sku.stocks.length)
                    .map((sku) => {
                        let selectedStock = sku.stocks.find(stock => Number(stock.stock_id) === Number(stock_id));
                        if (selectedStock && selectedStock.count > 0 && selectedStock.price > 0) {
                            let p = selectedStock.price;
                            if (typeof (p) === 'number')
                                p = String(p);
                            p = p.split('.')[0];
                            prices.push(p);
                        }
                    });
                if (prices.length) {
                    const min = Math.min.apply(null, prices);
                    const max = Math.max.apply(null, prices);
                    if (min !== max)
                        return  min + ' - ' + max;
                    else
                        return min
                }
                return null
            },

            /**
             * Id артикула с минимальной ценой для заданного склада
             *
             */
            minSku(stock_id) {
                let sku = null;
                let prices = [];
                this.skus
                    .filter(sku => sku.stocks.length)
                    .map((sku) => {
                        let selectedStock = sku.stocks.find(stock => Number(stock.stock_id) === Number(stock_id));
                        if (selectedStock && selectedStock.count > 0 && selectedStock.price > 0) {
                            let p = selectedStock.price;
                            if (typeof (p) === 'number')
                                p = String(p);
                            p = p.split('.')[0];
                            prices.push({
                                sku: {
                                    id: sku.id,
                                    name: sku.name
                                },
                                price: p
                            });
                        }
                    });
                if (prices.length) {
                    const min = Math.min.apply(null, prices.map(item => item.price));
                    if (min) {
                        let result = prices.filter(item => item.price == min)[0];
                        sku = result['sku']
                    }
                }
                return sku;
            },

            onChangeWindowWidth(width) {
                //mobile
                if (width <= 992) {
                    this.mobile_view = true;
                    $(window).on("scroll", function () {
                        var scrolled = $(this).scrollTop();
                        if (scrolled > 140) {
                            $('.product-page__cart-block').addClass('mobile-cart');
                        }
                        if (scrolled <= 140) {
                            $('.product-page__cart-block').removeClass('mobile-cart');
                        }
                    });
                } else {
                    this.mobile_view = false;
                }
            },

            async changeCount(event) {

                let icCart = this.selectedSkuInCart;
                if (isProxy(icCart)){
                    icCart = toRaw(icCart);
                }

                //изначальное кол-во товара в корзине
                const icCartCount = this.selectedSkuInCartCount();

                //измененное кол-во товара
                const newIcCartCount = event.target.value;

                //если кол-во товара увеличилось
                if (newIcCartCount > icCartCount) {

                    const sku = this.skus.find(sku => +sku.id === +icCart.sku_id);
                    const skuStock = sku.stocks.find(s => +s.stock_id === +icCart.stock_id);

                    if (newIcCartCount > skuStock.count) {
                        event.target.value = skuStock.count;
                        return this.bus.alert('Недостаточно товара на выбранном складе')
                    }
                }

                this.wait = true;

                try {
                    //если кол-во товара уменьшилось
                    if (newIcCartCount < icCartCount) {

                        if (icCartCount > 1) {
                            await window.api.changeCartItemCount(icCart.native_code, icCart.id, newIcCartCount)
                        } else {
                            await window.api.delCartItem(icCart.native_code, icCart.id);
                        }
                    } else {
                        await window.api.changeCartItemCount(icCart.native_code, icCart.id, newIcCartCount)
                    }
                    await window.bus.refreshCartInfo()
                } finally {
                    this.wait = false;
                    this.changeQuantity = false
                }
            },

            async addToCart() {

                if (!this.selectedStockId) {
                    this.bus.alert('Выберите склад');
                    this.$refs.stocks.scrollIntoView({block: "center", behavior: "smooth"});
                    return;
                }

                const skuWithStock = this.selectedSkuWithSelectedStock;

                this.wait = true;

                try {
                    if (this.selectedStockId && this.selectedSkuId) {
                        await window.api.addToCart(skuWithStock.stock_id, this.product.id, skuWithStock.id, 1)
                        await window.bus.refreshCartInfo();
                    }
                } catch (e) {
                    this.bus.message(e);
                    window.location.reload()
                } finally {
                    this.wait = false
                }
            },

            selectSku(sku) {
                if (sku.count_in_stock) {

                    this.selectedSkuId = +sku.id
                }
            },

            changeStock(event) {
                const stock_id = event.target.value;
                if (stock_id) {
                    const stock = {
                        id: stock_id
                    };
                    this.selectStock(stock);
                }
            },

            selectStock(stock) {

                this.selectedStockId = +stock.id;

                if (this.skus.length === 1) {
                    // Если один артикул, он всегда выбран
                    return
                }

                // Если при смене склада, на новом складе текущий выбранный артикул не имеет остатков,
                // то выбираем первый арикул с остатками (если он есть)

                if (this.selectedSkuId && this.selectedSkuWithSelectedStock.count_in_stock) {
                    // Все норм. Остатки есть
                    return
                }

                this.selectedSkuId = null;

                const existsSku = this.minSku(stock.id);

                if (existsSku) {
                    this.selectedSkuId = +existsSku.id
                }
            },


            addToFavorites() {
                const data = {product_id: this.product.id};

                $.post('/my/favorites/add/', data, (response) => {

                    if (response.status === 'fail') {
                        this.bus.message(response.errors)
                    } else {
                        this.product.in_favorites = true
                    }

                    this.bus.refreshFavorites()
                })
            },

            removeFromFavorites() {
                const data = {product_id: this.product.id};

                $.post('/my/favorites/del/', data, (response) => {

                    if (response.status === 'fail') {
                        this.bus.message(response.errors)
                    } else {
                        this.product.in_favorites = false
                    }

                    this.bus.refreshFavorites()
                })
            }
        }
    }
</script>