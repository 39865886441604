export default {

    getCartInfo() {

        return new Promise((resolve, reject) => {

            window.jQuery.get('/ajs/cart/items', (response) => {

                if (response.status === 'ok') {
                    resolve(response.data)
                } else {
                    reject(response.errors)
                }
            })

        })
    },

    addToCart(stockId, productId, skuId, quantity = 1) {

        return new Promise((resolve, reject) => {

            const data = {
                stock_id: stockId,
                product_id: productId,
                sku_id: skuId,
                quantity: quantity,
            }

            window.jQuery.post('/cart/add/?html=1', data, (response) => {

                if (response.status === 'ok') {

                    if (response.data.error) {
                        reject(response.data.error);
                    } else {
                        resolve(response.data)
                    }

                } else if (response.status === 'fail') {
                    reject(response.errors);
                }
            }, "json");
        })
    },

    changeCartItemCount(nativeCartCode, id, quantity) {

        return new Promise((resolve, reject) => {

            window.jQuery.post('/cart/save/?nativeCartCode=' + nativeCartCode, {html: 0, id, quantity}, function (response) {

                if (response.status === 'ok') {
                    resolve(response.data)
                } else {
                    reject(response.errors);
                }

            }, "json");

        })
    },

    delCartItem(nativeCartCode, id) {

        return new Promise((resolve) => {

            window.jQuery.post('/cart/delete/?nativeCartCode=' + nativeCartCode, {html: 0, id}, function (response) {

                if (response.status === 'ok') {
                    resolve(response.data)
                }

            }, "json");

        })
    },

    makeCheckout(form, nativeCartCode) {

        return new Promise((resolve, reject) => {

            window.jQuery.post('?step=checkout&nativeCartCode=' + nativeCartCode, form, function (response) {
                if (response.status === 'ok') {

                    if (response.errors) {

                        if (response.errors.form) {
                            reject(response.errors.form)
                        } else {
                            reject(response.errors.join('; '))
                        }

                    } else {
                        resolve(response.data)
                    }

                } else {
                    reject("Ошибка");
                }

            }, "json");

        })

    },

    makeOrder(form, nativeCartCode) {

        return new Promise((resolve, reject) => {

            window.jQuery.post('?step=make&nativeCartCode=' + nativeCartCode, form, function (response) {

                if (response.status === 'ok') {

                    if (response.errors) {

                        if (response.errors.form) {
                            reject(response.errors.form)
                        } else {
                            reject(response.errors)
                        }

                    } else {
                        resolve(response.data)
                    }

                } else {
                    reject("Ошибка");
                }

            }, "json");

        })

    }
}