<template>

    <div>

        <div class="row mb-3">

            <!--Модальное окно с катрой ПВЗ СДЕК--->
            <SdekModalWindow v-if="showSdekModal"
                             :city="customer.addressShipping.city"
                             :api-key="apiKey"
                             @closeModal="showSdekModal = false"
                             @change_SDEK_PVZ ="changeSDEKPVZ"
            >
            </SdekModalWindow>

            <!-- Левая часть -->
            <div class="col-md-8">
                <h4 class="my-3">
                    <span class="text text_color_gray mr-1">1.</span>
                    <span>Где и как вы хотите получить заказ?</span>
                </h4>

                <div class="row">
                    <div class="col-sm-6 mb-3">
                        <div class="p-3 h-100 checkout-page-select-block" @click="selectShippingType('flatrate')"
                             :class="{'checkout-page-select-block_active': selectedShippingType === 'flatrate'}">

                            <div class="block-left-right">
                                <div class="checkout-page-select-block__title">
                                    Доставка
                                </div>
                                <div class="checkout-page-select-block__circle d-none d-sm-block"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 mb-3">
                        <div class="p-3 h-100 checkout-page-select-block" @click="selectShippingType('pickup')"
                             :class="{'checkout-page-select-block_active': selectedShippingType === 'pickup'}">

                            <div class="block-left-right">
                                <div class="checkout-page-select-block__title">
                                    Самовывоз
                                </div>
                                <div class="checkout-page-select-block__circle d-none d-sm-block"></div>
                            </div>
                            <div></div>
                        </div>
                    </div>
                    
                </div>

                <select class="input mb-3" v-model="selectedShipping">
                    <option v-if="selectedShippingType === 'flatrate'" :value="null" disabled>
                        Выберите способ доставки
                    </option>
                    <option v-else :value="null" disabled>
                        Выберите пункт самовывоза
                    </option>
                    <template v-for="item in selectedTypeShipping" :key="item.id">
                        <option :value="item" >{{ item.name }}</option>
                    </template>
                </select>

                <template v-if="selectedShipping">
                    <div v-if="isSDEK" class="mb-3 mt-3">
                        <a class="checkout-page__pvz-select" @click="showSdekModal = true" ref="PVZsel">
                            <span class="text text_size_xxl">Выбрать пункт выдачи заказов</span>
                        </a>
                        <div v-if="PVZ" class="checkout-page__pvz-selected mt-2 mb-2">
                            Вы выбрали: {{PVZ}} <a @click="changeSDEKPVZ(null)"><i class="fa fa-times-circle"></i></a>
                        </div>
                    </div>
                    <div class="checkout-page__alert p-2 my-2">
                        <span v-html="selectedShipping.description"></span>
                    </div>
                </template>

                <!-- Выбран самовывоз, регион тоже необходимо указать -->
                <template v-if="selectedShippingType === 'pickup'">
                    <div class="row">
                        <div class="col-sm-6 mb-2">
                            <div>Страна<span class="text text_color_red">*</span></div>
                            <select class="input" v-model="customer.addressShipping.country" @change="setMaskPhone()">
                                <template v-for="c in countries" :key="c.code">
                                    <option :value="c.code">{{ c.name }}</option>
                                </template>
                            </select>
                        </div>

                        <div class="col-sm-6 mb-2">
                            <template v-if="customer.addressShipping.country=='rus'">
                                <div>Регион<span class="text text_color_red">*</span></div>
                                <select class="input" v-model="customer.addressShipping.region">
                                    <template v-for="r in regions" :key="r.code">
                                        <option :value="r.code">{{ r.name }}</option>
                                    </template>
                                </select>
                            </template>
                        </div>
                    </div>
                </template>

                <h4 class="my-3">
                    <span class="text text_color_gray mr-1">1.</span>
                    <span>Укажите данные получателя</span>
                </h4>

                <div class="row">

                    <div class="col-sm-4 mb-2">
                        <div>
                            Фамилия
                            <span class="text text_color_red">*</span>
                        </div>
                        <input type="text" class="input" v-model="customer.lastname" :required="!isAuth" :readonly="isAuth" @click="showSupport = true">
                    </div>

                    <div class="col-sm-4 mb-2">
                        <div>
                            Имя
                            <span class="text text_color_red">*</span>
                        </div>
                        <input type="text" class="input" v-model="customer.firstname" :required="!isAuth" :readonly="isAuth" @click="showSupport = true">
                    </div>

                    <div class="col-sm-4 mb-2">
                        <div>
                            Отчество
                            <span class="text text_color_red">*</span>
                        </div>
                        <input type="text" class="input" v-model="customer.middlename" :required="!isAuth && serverMiddlename === ''" :readonly="isAuth && serverMiddlename !== ''" @click="showSupportMiddleName = true">
                    </div>

                    <div class="col-sm-6 mb-2">
                        <div>
                            Телефон
                            <span class="text text_color_red">*</span>
                        </div>
                        <input type="tel" ref="phone" v-model="customer.phone" maxlength="18" class="input" :required="!isAuth && serverPhone === ''" :readonly="isAuth && serverPhone !== ''" @click="showSupportTel = true">
                    </div>

                    <div class="col-sm-6 mb-2">
                        <div>
                            Email
                            <span class="text text_color_red">*</span>
                        </div>
                        <input type="email" class="input" v-model="customer.email" required>
                    </div>

                    <div class="col-sm-12 mb-2 mt-2" v-show="(showSupport && isAuth) || (showSupportTel && isAuth && serverPhone !== '') || (showSupportMiddleName && isAuth && serverMiddlename !== '')">
                        <div style="border: 1px solid green; padding: 8px;">
                            Для изменения фамилии, имени, отчества или телефона необходимо написать на <a href="mailto:info@ajs.su">info@ajs.su</a>
                        </div>
                    </div>

                    <div class="col-sm-12 mb-2">
                        <div>
                            Комментарий
                        </div>
                        <textarea class="form-control mb-2" v-model="comment"></textarea>
                    </div>
                </div>

                <!--<div class="hr mt-3 mb-2"></div>-->

                <!-- Выбрана транспортная компания -->
                <template v-if="selectedShippingType === 'flatrate'">

                    <h4 class="my-3">
                        <span class="text text_color_gray mr-1">2.</span>
                        <span>Укажите адрес доставки</span>
                    </h4>

                    <div class="row">
                        <div class="col-sm-6 mb-2">
                            <div>Страна<span class="text text_color_red">*</span></div>
                            <select class="input" v-model="customer.addressShipping.country" @change="setMaskPhone()">
                                <template v-for="c in countries" :key="c.code">
                                    <option :value="c.code">{{ c.name }}</option>
                                </template>
                            </select>
                        </div>

                        <div class="col-sm-6 mb-2">
                            <template v-if="customer.addressShipping.country=='rus'">
                                <div>Регион<span class="text text_color_red">*</span></div>
                                <select class="input" v-model="customer.addressShipping.region">
                                    <option v-for="(r,index) in regions" :value="r.code" :key="index">
                                        {{ r.name }}
                                    </option>
                                </select>
                            </template>
                        </div>

                        <div class="col-sm-6 mb-2">
                            <div>Город<span class="text text_color_red">*</span></div>
                            <input type="text" class="input" required v-model="customer.addressShipping.city">
                        </div>

                        <div v-if="!isSDEK && !isEnergy && !isRussianPost" class="col-sm-6 mb-2">
                            <label for="need-crate-load">
                                <input type="checkbox" id="need-crate-load" class="mr-2" v-model="changeTerminal">
                                <span class="text text_decorator_underlined text_color_green text_size_lg cursor_pointer">
                                    <span>Доставка до терминала</span>
                                </span>
                            </label>
                        </div>

                        <div v-if="!isSDEK && !isEnergy && !isRussianPost && changeTerminal" class="col-sm-12 mb-2">
                            <div>Адрес терминала<span class="text text_color_red">*</span></div>
                            <textarea class="form-control mb-2" v-model="terminalAddress"></textarea>
                        </div>

                        <template v-else>
                            <div class="col-sm-6 mb-2">
                                <div>Улица, дом, квартира</div>
                                <input type="text" class="input" v-model="customer.addressShipping.street">
                            </div>

                            <div class="col-sm-6 mb-2">
                                <div>Индекс</div>
                                <input type="text" class="input" v-model="customer.addressShipping.zip">
                            </div>

                            <div class="col-sm-6 mb-2" v-if="requirePassport">
                                <div>Паспорт</div>
                                <input type="text" class="input" v-model="customer.passport">
                            </div>
                        </template>
                    </div>
                </template>

                <div class="row">
                    <div class="col-12 mb-2" v-if="isCompany">
                        <div>
                            Грузополучатель
                        </div>
                        <input type="text" class="input" v-model="customer.gruzopoluchatel" placeholder="Иванов Иван">
                    </div>
                </div>

                <!------------------------------------------------------------------------------------------------------>
                <!-- Способы упаковки -->

                <h4 class="my-3">
                    <span class="text text_color_gray mr-1">
                        <template v-if="selectedShippingType === 'pickup'">
                            2.
                        </template>
                        <template v-else>
                            3.
                        </template>
                    </span>
                    <span>Выберите способ упаковки</span>
                </h4>

                <template v-for="(crate,index) in CrateVariants" :key="index">
                    <input type="radio" :id="index" :value="crate.value" v-model="needCrate"
                           @click="UnselectPackagingOption">
                    <label style="margin-left: 5px" :for="index">{{crate.name}}</label>
                    <br>
                </template>

                <!-- Если есть другие способы упаковки, то отображаем их -->
                <!--<template v-if="packaging_options && !needCrate">
                    <select class="input mb-2" v-model="selectedPackagingOption" ref="packOpt"
                            onchange="$(this).css('border','1px solid #ced4da')" style="border: 1px solid #00683e;">
                        <option :value="null" disabled>
                            *Выберите способ упаковки
                        </option>
                        <template v-for="option in packaging_options">
                            <option :value="option" :key="option.id">{{ option.name }}</option>
                        </template>
                    </select>
                </template>-->

                <!------------------------------------------------------------------------------------------------------>
                <!-- Оплата -->

                <h4 class="my-3" v-show="payMethods.length !== 0">
                    <span class="text text_color_gray mr-1">
                        <template v-if="selectedShippingType === 'pickup'">
                            3.
                        </template>
                        <template v-else>
                            4.
                        </template>
                    </span>
                    <span>Как вам будет удобнее оплатить заказ?</span>
                </h4>

                <div class="row">
                    <template v-for="(method,index) in countryPayMethods" :key="method.id">
                        <div class="col-6 col-sm-4 p-2" @click="selectPayment(method)">

                            <div class="checkout-page-select-block mb-2 p-2"
                                 :class="{'checkout-page-select-block_active': selectedPayment ? method.id === selectedPayment.id : false}">

                                <!--<img :src="method.logo" class="checkout-page__payment-way-image">-->
                                <div class="d-sm-block" :class="{'text_color_green': selectedPayment ? method.id === selectedPayment.id : false}">
                                    <template v-if="index === 0">
                                        <span class="text_color_dark-gray" style="font-weight: bold">{{ method.name }}</span>
                                    </template>
                                    <template v-else>
                                        <span>{{ method.name }}</span>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>

                <div class="checkout-page__alert p-2 my-2" v-if="selectedPayment">
                    <span v-html="selectedPayment.description"></span>
                </div>

                <template v-if="!isAuth">
                    <div class="my-2">
                        <label>
                            <input type="checkbox" class="mr-2" v-model="termsAccepted">
                            <span>Я даю свое согласие на обработку моих персональных данных. </span>
                            <a class="text text_color_green text_decorator_underlined" href="/personal-data/"
                               target="_blank">Узнать подробности.</a>
                        </label>
                    </div>
                </template>

                <!-----------------Бонусы------------->
                <template v-if="isAuth && customer.balanceOfBonuses > 0 && (selectedPayment && selectedPayment.use_bonuses)">
                    <h4 class="my-3">
                        <span>Используйте бонусы</span>
                    </h4>
                    <div class="mb-4 mt-3">
                        <span>Доступно: </span>
                        <span class="mr-2 text_color_green text_weight_bold">{{customer.maxBonuses}}</span>/

                        <span>Использовать: </span>
                        <input type="number" step="1" name="use_bonus" class="input input_width_100 mr-2"
                               placeholder="Бонусы" min="0" v-model="spendBonuses" :max="customer.maxBonuses"
                               @input="validateBonuses()">
                        <br>
                        <span class="text_color_gray">Всего: </span>
                        <span class="mr-2 text_color_gray text_weight_bold">{{customer.balanceOfBonuses}}</span>
                    </div>
                </template>

                <div class="row mb-2 mt-3">
                    <div class="col-6 text-left">
                        <button class="button button_color_green" @click="toCart" :disabled="wait">
                            Вернуться в корзину
                        </button>
                    </div>
                    <div class="col-6 text-right">
                        <button class="button button_color_green" @click="next" :disabled="wait">
                            Далее &gt;&gt;
                        </button>
                    </div>
                </div>


                <!------------------------------------------------------------------------------------------------------>
                <!-- Нижняя часть -->

                <!--<h4 class="my-3">-->
                <!--<span class="text text_color_gray mr-1">3.</span>-->
                <!--<span>Скидки к заказу</span>-->
                <!--</h4>-->

                <!--<div class="my-2">-->
                <!--<input type="checkbox" disabled class="mr-2">-->
                <!--<span class="text text_color_light-gray">Скидка при оплате онлайн</span>-->
                <!--</div>-->

                <!--<div class="my-2">-->
                <!--<label>-->
                <!--<input type="checkbox" class="mr-2">-->
                <!--<span>Промокод</span>-->
                <!--</label>-->
                <!--</div>-->

                <!--<div class="my-3">-->
                <!--<div class="block-left-right block-left-right_no-mobile">-->
                <!--<div class="checkout-page__price-text pr-2">-->
                <!--<span class="text text_size_lg">Товары</span>-->
                <!--</div>-->

                <!--<div class="checkout-page__price-text pl-2">-->
                <!--<span class="text text_size_lg text_weight_bold">{{ price }} руб.</span>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->

                <!--<div class="my-3">-->
                <!--<div class="block-left-right block-left-right_no-mobile">-->
                <!--<div class="checkout-page__price-text pr-2">-->
                <!--<span class="text text_size_lg">Забрать в магазине</span>-->
                <!--</div>-->

                <!--<div class="checkout-page__price-text pl-2">-->
                <!--<span class="text text_size_lg text_weight_bold" v-if="!selectedShipping || !selectedShipping.rate">-->
                <!--Бесплатно-->
                <!--</span>-->
                <!--<span class="text text_size_lg text_weight_bold" v-else>-->
                <!--{{ selectedShipping.rate }}-->
                <!--</span>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->

                <!--<div class="my-3">-->
                <!--<div class="block-left-right block-left-right_no-mobile">-->
                <!--<div class="checkout-page__price-text pr-2">-->
                <!--<span class="text text_size_lg">Итого к оплате</span>-->
                <!--</div>-->

                <!--<div class="checkout-page__price-text pl-2">-->
                <!--<span class="text text_size_xl text_weight_bold">{{ totalPrice }} руб.</span>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->

            </div>

            <!------------------------------------------------------------------------------------------------------>
            <!-- Правая часть -->
            <div class="col-md-4">
                <div class="checkout-page__gray-block text p-3 my-3">
                    <div class="my-3">
                        <h3>{{ currentStock.name }}</h3>
                    </div>

                    <div class="my-3">
                        Количество позиций <span class="text_weight_bold">{{ items.length }}</span>
                        <br>
                        На сумму <span class="text_weight_bold">{{ Number(price).priceFormat(2) }} Р</span>
                    </div>

                    <template v-for="i in items" :key="i.id">
                        <div class="my-3">
                            {{ i.product.name }}
                            <br>
                            <span class="text_weight_bold">{{ i.quantity }} шт. х {{ Number(i.price).priceFormat(2) }} Р</span>
                        </div>
                    </template>
                </div>

                <!--<div class="checkout-page__gray-block p-3 my-3">-->
                <!--<div class="my-3">-->
                <!--<span class="text text_color_gray">Скидки на следующий заказ</span>-->
                <!--</div>-->

                <!--<div class="my-3">-->
                <!--<span class="text">Купон на следующую покупку</span>-->
                <!--</div>-->
                <!--</div>-->

            </div>
        </div>

    </div>
</template>

<script>
    import {objectToArray} from "../../common";
    import SdekModalWindow from "./SdekModalWindow";

    export default {

        components: {
            SdekModalWindow
        },

        created() {
            this.countries = objectToArray(JSON.parse(document.getElementById('checkout-countries').value));
            this.regions = window.regions;
            this.items = objectToArray(JSON.parse(document.getElementById('checkout-items').value))
            this.shippingMethods = objectToArray(JSON.parse(document.getElementById('checkout-shipping-methods').value))
            this.paymentMethods = objectToArray(JSON.parse(document.getElementById('checkout-payment-methods').value))
            this.price = JSON.parse(document.getElementById('checkout-total').value)
            this.nativeCartCode = document.getElementById('checkout-native-cart-code').value
            this.customer = JSON.parse(document.getElementById('checkout-form-values').value)

            this.packaging_options = objectToArray(JSON.parse(document.getElementById('packaging_options').value))
            this.country_rules = JSON.parse(document.getElementById('country_rules').value)

            this.serverPhone = this.customer.phone;
            this.serverMiddlename = this.customer.middlename;
            //отображаем телефоный номер без телефонного кода
            this.customer.phone = this.customer.phone.replace(this.selectedTelCode, "");
            //запоминаем телефон без кода, чтобы потом сравнить его с телефоном перед сохранением данных
            this.tel_without_code = this.customer.phone;

            this.apiKey = document.getElementById('apiKey').value;
        },

        mounted() {
            this.setMaskPhone();

            /*   let number = this.customer.phone

              if (number && number.slice(0, 1) === '7') {
                 number = number.slice(1)
               }

               $(this.$refs.phone).val(number);

               $(this.$refs.phone).inputmask('mask', {
                   mask: '+7 (999) 999-9999'
               });*/
        },

        data: () => ({
            termsAccepted: false,
            shippingMethods: null,
            paymentMethods: null,
            countries: null,
            regions: null,
            items: null,
            price: 0,
            tel_without_code: null,
            packaging_options: null,
            country_rules: null,
            selectedPackagingOption: null,
            CrateVariants: {
                1: {
                    name: "Нужна обрешетка",
                    value: "Нужна обрешетка"
                },
                2: {
                    name: "Нужен Паллет борт",
                    value: "Нужен Паллет борт"
                },
                3: {
                    name: "Без упаковки",
                    value: false
                }
            },

            needCrate: false,  //нужна обрешeтка
            comment: '',
            wait: false,

            customer: {
                gruzopoluchatel: '',
                firstname: '',
                lastname: '',
                middlename: '',
                phone: '',
                email: '',
                addressShipping: {
                    country: 'rus',
                    city: '',
                    region: '',
                    street: '',
                    zip: '',
                    //в этот ключ добавляем альтернаивную валюту
                    currency: null
                },
            },

            selectedShippingType: 'flatrate', //  самовывоз(pickup) или доставка ТК (flatrate)
            selectedShipping: null,
            selectedPayment: null,
            activeSelectShipping: null,

            showSdekModal: false,
            sdekPVZ: null,
            spendBonuses: 0,
            showSupport: false,
            showSupportTel: false,
            showSupportMiddleName: false,
            serverPhone: null, //телефон, который получаем  сервера,
            serverMiddlename: null, //отчество с сервера

            changeTerminal: false,
            terminalAddress: '',
            apiKey: ''
        }),

        computed: {
            bus: () => window.bus,

            selectedTelCode() {
                let country_tel_code = this.countries.filter(d => d.code.indexOf(this.customer.addressShipping.country) !== -1);
                //возвращаем телефонный код страны без '+'
                return country_tel_code[0].tel_code.substr(1);
            },

            isAuth: () => window.wa_is_auth,

            isCompany() {
                return this.customer.is_company_selected === '1'
            },

            currentStock() {
                const currentStockId = +document.getElementById('checkout-stock-id').value

                return window.shop_stocks.find(s => s.id === currentStockId)
            },

            payMethods() {
                if (!this.selectedShipping) {
                    return []
                }
                return this
                    .paymentMethods
                    .filter(p => p.shipping.includes(this.selectedShipping.id))
                    .map(p => {

                        //получаем все, что внутри квадратных скобок
                        const match = p.name.match(/\[(.+?)\] (.*)/);

                        // Если нет совпадения, значит нет префикса. Показываем всегда.
                        if (!match) {
                            return p
                        }

                        // Префикс есть.
                        // Если значение из квадратных скобок не совпадает с нужным нам складом,
                        // то это нам не надо.
                        if (match[1] !== this.activeSelectShipping) {
                            return null
                        }

                        // Возвращаем имя с префиксом(без префикса match[2], match[1]-сам префикс)
                        return Object.assign({}, p, {name: match[0]})
                    })
                    .filter(p => p)
            },

            //отображение платежей, в зависимости от страны
            countryPayMethods() {
                if (!this.country_rules)
                    return this.payMethods;
                const self = this;
                return this
                    .payMethods
                    .map(p => {
                        if (self.country_rules[p.id] && Number(self.country_rules[p.id][self.customer.addressShipping.country]) === 1)
                            return null;
                        return p
                    })
                    .filter(p => p)
            },

            //валюты
            countryCurrency() {
                let selectedCountry = this.customer.addressShipping.country;
                if (!selectedCountry)
                    return null;
                return this
                    .countries
                    .map(c => {
                        if (c.code === selectedCountry && c.other_currency_code)
                            return {code: c.other_currency_code, rate: c.other_currency_rate};
                        return null;
                    })
                    .filter(c => c)
            },

            selectedTypeShipping() {

                const currentStockName = this.currentStock.name

                return this
                    .shippingMethods
                    //для AJS ECOM указываем доп.условие
                    .filter(i => (i.plugin === this.selectedShippingType) || (this.selectedShippingType === 'flatrate' && i.plugin === 'ajsflatrate'))
                    .map(i => {

                        //получаем все, что внутри квадратных скобок
                        const match = i.name.match(/\[(.+?)\] (.*)/);   ///\[([а-яА-Я -]*)\] (.*)/

                        // Если нет совпадения, значит нет префикса. Показываем всегда.
                        if (!match) {
                            return i
                        }

                        // Префикс есть.
                        // Если значение из квадратных скобок не совпадает с нужным нам складом,
                        // то это нам не надо.
                        if (match[1] !== currentStockName) {
                            return null
                        }
                        //запоминаем сам префикс, чтобы потом сравнить его с префиксом в payMethods()
                        this.activeSelectShipping = match[1];
                        // Возвращаем имя с префиксом(без префикса match[2], match[1]-сам префикс)
                        return Object.assign({}, i, {name: match[0]})
                    })
                    .filter(i => i)
            },

            requirePassport() {

                if (!this.selectedShipping) {
                    return false
                }

                return [
                    'т\\к ПЭК',
                    'т\\к Деловые линии'
                ].indexOf(String(this.selectedShipping.name).trim()) !== -1
            },

            //сдэк
            isSDEK(){
                if (!this.selectedShipping) {
                    return false
                }
                return this.selectedShipping.name.includes('СДЭК');
            },

            //энергия
            isEnergy(){
                if (!this.selectedShipping) {
                    return false
                }
                return this.selectedShipping.name.includes('Энергия');
            },

            //почта России
            isRussianPost(){
                if (!this.selectedShipping) {
                    return false
                }
                return this.selectedShipping.name.includes('Почта России');
            },

            /**
             * @return {null}
             */
            PVZ(){
                return this.sdekPVZ
            }
        },

        methods: {

            //Чтобы не получилось ввести больше, чем 30% бонусов
            validateBonuses() {
                this.spendBonuses = this.valBetween(this.spendBonuses, 0, this.customer.maxBonuses);
            },

            valBetween(v, min, max) {
                return (Math.min(max, Math.max(min, v)));
            },

            changeSDEKPVZ(pvz){
                var self = this;
                self.sdekPVZ = pvz;
                self.$refs.PVZsel.scrollIntoView({block: "center", behavior: "smooth"});
            },

            UnselectPackagingOption() {
                this.selectedPackagingOption = null;
            },

            setMaskPhone() {
                if (this.customer.addressShipping.country === 'rus' || this.customer.addressShipping.country === 'kaz') {
                    $(this.$refs.phone).inputmask('mask', {
                        mask: '+7 (999) 999-9999'
                    });
                } else {

                    $(this.$refs.phone).inputmask({
                        mask: '+' + this.selectedTelCode + ' (dd) ddd-dddd',
                        definitions: {
                            '9': '',
                            'd': '[0-9]',
                        }
                    });
                }
                $(this.$refs.phone).on('change', (e) => {
                    this.customer.phone = e.target.value;
                })
            },

            selectShippingType(type) {
                this.selectedShippingType = type;
                this.selectedShipping = null;
            },

            selectPayment(method) {
                this.selectedPayment = method
            },

            toCart() {
                window.location.href = '/cart';
            },

            yandexDataLayer_makeCheckout() {
                let products = [];
                this.items.forEach((item) => {
                    products.push(
                        {id: item.id},
                        {name: item.name},
                        {price: item.price},
                        {quantity: item.quantity},
                    )
                });
                // window.dataLayer.push({
                //     "ecommerce": {
                //         "purchase": {
                //             "actionField": {
                //                 "id": this.nativeCartCode
                //             },
                //             "products": products
                //         }
                //     }
                // });
            },


            async next() {

                /*if (this.customer.addressShipping.country === 'kaz'
                    ||  this.customer.addressShipping.country === 'arm'
                    ||  this.customer.addressShipping.country === 'kgz'
                )
                return this.bus.message('Доставка в выбранную страну временно не осуществляется. Приносим извинения за доставленные неудобства.');
                 */

                if (!this.selectedShipping) return this.bus.alert('Необходимо выбрать способ доставки.')
                if (!this.selectedPayment) return this.bus.alert('Необходимо выбрать способ оплаты')
                if (!this.customer.lastname && !this.isCompany) return this.bus.alert('Необходимо заполнить фамилию')
                if (!this.customer.firstname && !this.isCompany) return this.bus.alert('Укажите имя')
                if (!this.customer.middlename && !this.isCompany) return this.bus.alert('Укажите отчество')
                if (!this.customer.phone) return this.bus.alert('Укажите телефон')
                if (!this.customer.email) return this.bus.alert('Укажите email')

                /*if (this.isSDEK && !this.PVZ)
                {
                    this.bus.alert('Необходимо выбрать пункт выдачи заказов');
                    this.$refs.PVZsel.scrollIntoView({block: "center", behavior: "smooth"});
                    return
                }*/

                if (this.changeTerminal && this.terminalAddress === '' && !this.isSDEK && !this.isEnergy && !this.isRussianPost)
                {
                    this.bus.alert('Необходимо указать адрес терминала');
                    return
                }

                if (this.customer.addressShipping.country !== 'rus')
                    this.customer.addressShipping.region = '';
                else {
                    if (!this.customer.addressShipping.region)
                        return this.bus.alert('Необходимо выбрать регион.');
                }

                /*if (!this.selectedPackagingOption && this.packaging_options && !this.needCrate) {
                    this.bus.alert('Необходимо выбрать способ упаковки');
                    this.$refs.packOpt.focus();
                    this.$refs.packOpt.scrollIntoView({block: "center", behavior: "smooth"});
                    return
                }*/


                if (!this.isAuth) {
                    if (!this.termsAccepted) return this.bus.alert('Необходимо подтвердить согласие на обработу персональных данных')
                }

                //Если телефон не редактировали (и он так и остался без кода), то добавляем его
                if (this.tel_without_code === this.customer.phone) {
                    this.customer.phone = '+' + this.selectedTelCode + this.tel_without_code;
                }

                //валюта
                if (this.countryCurrency.length) {
                    const currency =  this.countryCurrency[0];
                    //if (confirm('Конветировать стоимость заказа в ' + currency.code + ' исходя из курса ' + currency.rate + '?'))
                       this.customer.addressShipping.currency = currency.code;
                    //else
                        //this.customer.addressShipping.currency = null;
                }

                //если бонусы для выбранного способа оплаты нельзя потратить,
                //(то на всякий случай их обнуляем)
                if (!this.selectedPayment.use_bonuses)
                    this.spendBonuses = 0;

                const form = {
                    comment: '',
                    packaging_option: this.selectedPackagingOption,
                    payment_id: +this.selectedPayment.id,
                    shipping_id: +this.selectedShipping.id,
                    terms_accepted: this.termsAccepted ? 1 : 0,
                    customer: {
                        phone: this.customer.phone,
                        email: this.customer.email,
                        'address.shipping': {}
                    },
                    spendBonuses: this.spendBonuses
                };

                form.customer.firstname = this.customer.firstname
                form.customer.lastname = this.customer.lastname
                form.customer.middlename = this.customer.middlename
                form.customer.passport = this.customer.passport

                if (this.isCompany) {
                    form.customer.gruzopoluchatel = this.customer.gruzopoluchatel
                }

                if (this.needCrate) {
                    form.comment = this.needCrate + '; ' + this.comment;
                } else
                    form.comment = this.comment;

                if (this.PVZ)
                    form.comment = form.comment + ' ' + this.PVZ;

                if (this.terminalAddress !== '')
                    form.comment = form.comment + ' Доставка до терминала: ' + this.terminalAddress;

                // Если транспортная компания или есть валюта
                if (this.selectedShippingType === 'flatrate' || this.customer.addressShipping.currency) {
                    Object.keys(this.customer.addressShipping).forEach(k => {
                        form.customer['address.shipping'][k] = this.customer.addressShipping[k]
                    })
                }

                this.wait = true

                try {
                    const response = await window.api.makeCheckout(form, this.nativeCartCode)

                    this.yandexDataLayer_makeCheckout();

                    this.$emit('onCheckout', response)

                } catch (e) {

                    if (typeof e === 'string') {
                        this.bus.message(e)
                    } else {
                        // Ошибка валидации формы.
                        Object.keys(e).map(k => {
                            this.bus.message((k ? k : '') + e[k].join(';'))
                        })
                    }
                } finally {
                    this.wait = false
                }
            }
        }
    }
</script>